import { useCallback } from "react";
import { DbDocument } from "../model/DbDocument";
import { DocumentUploadType } from "../model/DocumentUploadType";
import { BaseGame } from "../model/Game/BaseGame";
import { UpdateGameRequest } from "../model/Request/UpdateGameRequest";
import { ContextFunc, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from "../services/FetchHelper";
import { GameType } from "../model/Game/GameType";

export interface GameSettings{ skin: string; document: DocumentUploadType|DbDocument|undefined }
interface UpdateBaseGame{id: string; model: UpdateGameRequest;}
interface AuthorRequst{ gameId: string; authorId: string;}

export interface CreateCopyRequest{
    languageCode?: string;
    keepDocument: boolean;
    translator: "None"|"Google"|"DeepL";
}

export interface IBaseGameContext<T extends BaseGame>{
    useGameUpdate: ContextFunc<T, [UpdateBaseGame]>;
    useGameTrash: ContextFunc<{id: string}, [string]>;
    useGameDelete: ContextFunc<{id: string}, [string]>;
    useAddAuthor: ContextFunc<T, [AuthorRequst]>;
    useRemoveAuthor: ContextFunc<T, [AuthorRequst]>;
    useTransferOwner: ContextFunc<T, [AuthorRequst]>;
    useCreateCopy: ContextFunc<T, [{id: string, model: CreateCopyRequest}]>;
    useCreate: ContextFunc<T, []>;
    useGame: ContextFunc<T, [string]>;
    gameType: GameType;
}

export const createBaseGameContext: <T extends BaseGame> (gameType: GameType) => IBaseGameContext<T> = (gameType) => ({
    useGame: <T> () => {
        const [rawInvoke, loading, error] = useFetchGet<T>();
        const invoke = useCallback((id: string) => rawInvoke(`api/workshop/${gameType}/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGameUpdate: <T> () => {
        const [rawInvoke, loading, error] = useFetchPut<T>();
        const invoke = useCallback(
            (args: UpdateBaseGame) => rawInvoke(`api/workshop/${gameType}/${args.id}`, args.model)
        ,[rawInvoke]);
        return [invoke, loading, error];
    },
    useGameTrash: () => {
        const [rawInvoke, loading, error] = useFetchDelete<{id: string}>();
        const invoke = useCallback((id: string) => rawInvoke(`api/workshop/${gameType}/${id}`)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useGameDelete: () => {
        const [rawInvoke, loading, error] = useFetchDelete<{id: string}>();
        const invoke = useCallback((id: string) => rawInvoke(`api/workshop/${gameType}/${id}/perm`)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useAddAuthor: <T> () => {
        const [rawInvoke, loading, error] = useFetchPost<T>();
        const invoke = useCallback(
            (args: AuthorRequst) => rawInvoke(`api/workshop/${gameType}/${args.gameId}/author/${args.authorId}`)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveAuthor: <T> () => {
        const [rawInvoke, loading, error] = useFetchDelete<T>();
        const invoke = useCallback(
            (args: AuthorRequst) => rawInvoke(`api/workshop/${gameType}/${args.gameId}/author/${args.authorId}`)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useTransferOwner: <T> () => {
        const [rawInvoke, loading, error] = useFetchPost<T>();
        const invoke = useCallback(
            (args: AuthorRequst) => rawInvoke(`api/workshop/${gameType}/${args.gameId}/transferOwner/${args.authorId}`)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreateCopy: <T> () => {
        const [rawInvoke, loading, error] = useFetchPost<T>();
        const invoke = useCallback(
            (args: {id: string, model: CreateCopyRequest}) => rawInvoke(`api/workshop/${gameType}/${args.id}/copy`, args.model)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreate: <T> () => {
        const [rawInvoke, loading, error] = useFetchPost<T>();
        const invoke = useCallback(() => rawInvoke(`api/workshop/${gameType}/create`), [rawInvoke]);
        return [invoke, loading, error];
    },
    gameType
});