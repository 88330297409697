import DangerInTheJungle from '../../images/games/icon_dangerinthejungle.png';
import Airhockey from '../../images/games/icon_airhockey.png';
import AwesomeForest from '../../images/games/icon_awesomeforest.png'; 
import BuzzIt from '../../images/games/icon_buzzit.png'; 
import BikeRace from '../../images/games/icon_bikerace.png'; 
import PictureBook from '../../images/games/icon_picturebook.png'; 
import Garden from '../../images/games/icon_flowergarden.png'; 
import Combination from '../../images/games/icon_combination.png'; 
import Memory from '../../images/games/icon_memory.png'; 
import WordWizard from '../../images/games/icon_wordwizard.png'; 
import Geekster from '../../images/games/icon_geekster.png'; 
import Drums from '../../images/games/icon_drums.png'; 
import SplatterSpace from '../../images/games/icon_splatterspace.png'; 
import QuizNinja from '../../images/games/icon_quizninja.png'; 
import SmackTheFly from '../../images/games/icon_smackthefly.png'; 
import DJLab from '../../images/games/icon_djlab.png'; 
import Soccer from '../../images/games/icon_soccer.png'; 
import BrickOut from '../../images/games/icon_brickOut.png'; 
import SpinTheBottle from '../../images/games/icon_spinthebottle.png'; 
import TvLounge from '../../images/games/icon_tvlounge.png'; 
import WallBall from '../../images/games/icon_wall.png'; 
import WarOfStrategy from '../../images/games/icon_pirateislands.png'; 
import SimonSays from '../../images/games/icon_simonsays.png'; 
import Fishes from '../../images/games/icon_fishes.png'; 
import Butterflies from '../../images/games/icon_butterflies.png'; 
import Sakura from '../../images/games/icon_sakura.png'; 
import Chess from '../../images/games/icon_chess.png'; 
import JigsawPuzzle from '../../images/games/icon_jigsawpuzzle.png'; 
import Presentation from '../../images/games/icon_presentation.png'; 
import PresentationGame from '../../images/games/icon_presentation.png'; 
import SuperSorter from '../../images/games/icon_SuperSorter.png'; 
import Ordering from '../../images/games/icon_ordering.png'; 
import Painter from '../../images/games/icon_painter.png'; 
import GalacticGolf from '../../images/games/icon_galacticgolf.png';
import PopIt from '../../images/games/icon_popit.png';
import { GameType } from '../../model/Game/GameType';
import WordFinder from '../../images/games/icon_wordFinder.png'; 
import DragonDice from '../../images/games/icon_dragonDice.png'; 
import PizzaParty from '../../images/games/icon_pizzaparty.png'; 
import EscapeRoom from '../../images/games/icon_escaperoom.png'; 
import Globetrotter from '../../images/games/icon_globetrotter.png'; 

export const GameIcons: {[key: GameType]: string} = {
    DangerInTheJungle, 
    Airhockey, 
    AwesomeForest, 
    BuzzIt, 
    BikeRace, 
    PictureBook, 
    Garden,
    Combination, 
    Memory, 
    WordWizard, 
    Geekster, 
    Drums, 
    SplatterSpace, 
    QuizNinja, 
    SmackTheFly, 
    DJLab, 
    Soccer, 
    BrickOut, 
    SpinTheBottle, 
    TvLounge, 
    WallBall, 
    WarOfStrategy, 
    SimonSays, 
    Fishes, 
    Butterflies, 
    Sakura, 
    Chess, 
    JigsawPuzzle, 
    Presentation, 
    PresentationGame,
    SuperSorter, 
    Ordering, 
    Painter,
    GalacticGolf, 
    PopIt, 
    WordFinder, 
    DragonDice, 
    PizzaParty, 
    EscapeRoom,
    Globetrotter
}