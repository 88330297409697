import { useCallback, useContext } from "react";
import { MinimalOrganization } from "../model/Response/MinimalOrganization";
import { ContextFunc, ContextReturn, isFetchError, useFetchDelete, useFetchGet, useFetchPost, useFetchPut } from "../services/FetchHelper";
import { EditOrganization, Organization } from "../model/Organization";
import { GlobalModalContext, SecureContext } from "../components/_MyFloor/MyFloorApp";
import { SearchResult } from "../model/Response/SearchResult";
import { OrganziationSearchModel } from "../model/Request/SearchModel";
import { accountIsAdmin } from "../model/AccountType";
import { CreateOrgOtherContact } from "../model/Request/CreateOrgOtherContact";
import { Manager } from "../model/Manager";
import { EditOrganizationResponse } from "../model/Response/EditOrganizationResponse";
import { DealRegOrg } from "../model/Response/OrganizationDto/DealRegOrg";


export interface IOrganizationContext{
    useAllMinimal: ContextFunc<MinimalOrganization[], []>;
    useSearch: ContextFunc<SearchResult<Organization>, [OrganziationSearchModel]>;
    useOrganization: ContextFunc<Organization, [string]>;
    useOrganizationMinimal: ContextFunc<MinimalOrganization, [string]>;
    useUpdate: ContextFunc<EditOrganizationResponse, [EditOrganization]>;
    useCreate: ContextFunc<EditOrganizationResponse, [EditOrganization]>;
    useDelete: ContextFunc<void, [string]>;
    useAddChild: ContextFunc<Organization, [string, string]>;
    useRemoveChild: ContextFunc<Organization, [string, string]>;
    useOrgLanguages: ContextFunc<string[],[]>;
    useOrgTypes: ContextFunc<string[],[]>;
    useOrgCountries: ContextFunc<string[],[]>;
    useResellers: ContextFunc<Organization[], []>;
    useManagers: ContextFunc<Manager[], [string]>;
    useChildren: ContextFunc<Organization[], [string]>;
    useCreateOtherContact: ContextFunc<Organization, [string, CreateOrgOtherContact]>;
    useUpdateOtherContact: ContextFunc<Organization, [string, string, CreateOrgOtherContact]>;
    useRemoveOtherContact: ContextFunc<Organization, [string, string]>;
    useDealRegOrgs: ContextFunc<DealRegOrg[], []>;
}

const baseUrl = "api/organization";

export const OrganizationContext: IOrganizationContext = {
    useChildren: () => {
        const [rawInvoke, loading, error] = useFetchGet<Organization[]>();
        const invoke = useCallback((id: string) => rawInvoke(`${baseUrl}/${id}/children`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAllMinimal: () => {
        const [rawInvoke, loading, error] = useFetchGet<MinimalOrganization[]>();
        const invoke = useCallback(() => rawInvoke(`${baseUrl}/all/minimal`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useSearch: () => {
        const [rawInvoke, loading, error] = useFetchPost<SearchResult<Organization>>();
        const invoke = useCallback((model: OrganziationSearchModel) => rawInvoke(`${baseUrl}/search`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrganization: () => {
        const [rawInvoke, loading, error] = useFetchGet<Organization>();
        const invoke = useCallback((id: string) => rawInvoke(`${baseUrl}/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrganizationMinimal: () => {
        const [rawInvoke, loading, error] = useFetchGet<MinimalOrganization>();
        const invoke = useCallback((id: string) => rawInvoke(`${baseUrl}/${id}/minimal`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdate: () => {
        const { myOrg, setMyOrg } = useContext(SecureContext);
        const [rawInvoke, loading, error] = useFetchPut<EditOrganizationResponse>(result => {
            if (myOrg?.id === result.organization.id) setMyOrg(result.organization);
        });
        const invoke = useCallback((model: Organization) => rawInvoke(`${baseUrl}/${model.id}`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreate: () => {
        const {me, setMyOrg} = useContext(SecureContext);
        const [rawInvoke, loading, error] = useFetchPost<EditOrganizationResponse>();
        const invoke = useCallback(async (model: Organization) => {
            const result = await rawInvoke(`${baseUrl}`, model);
            if(!isFetchError(result) && !accountIsAdmin(me)){
                setMyOrg(o => o && ({...o, childOrganizationIds: [...o?.childOrganizationIds, result.organization.id]}));
            } 
            return result;
        }, [rawInvoke, me, setMyOrg]);
        return [invoke, loading, error];
    },
    useDelete: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((id: string) => rawInvoke(`${baseUrl}/${id}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAddChild: () => {
        const { myOrg, setMyOrg } = useContext(SecureContext);
        const { popMsg } = useContext(GlobalModalContext);
        const [rawInvoke, loading, error] = useFetchPost<Organization>(o => {
            if (myOrg?.id === o.id) setMyOrg(o);
            popMsg("pop_change", "pop_msg_organization_changed");
        });
        const invoke = useCallback((orgId: string, childId: string) => rawInvoke(`${baseUrl}/${orgId}/child/${childId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveChild: () => {
        const { myOrg, setMyOrg } = useContext(SecureContext);
        const [rawInvoke, loading, error] = useFetchDelete<Organization>(o => {
            if (myOrg?.id === o.id) setMyOrg(o);
        });
        const invoke = useCallback((orgId: string, childId: string) => rawInvoke(`${baseUrl}/${orgId}/child/${childId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrgLanguages: () => {
        const [rawInvoke, loading, error] = useFetchGet<string[]>();
        const invoke = useCallback(() => rawInvoke('api/organization/unique/languages'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrgTypes: () => {
        const [rawInvoke, loading, error] = useFetchGet<string[]>();
        const invoke = useCallback(() => rawInvoke('api/organization/unique/types'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useOrgCountries: () => {
        const [rawInvoke, loading, error] = useFetchGet<string[]>();
        const invoke = useCallback(() => rawInvoke('api/organization/unique/countries'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useResellers: function (): ContextReturn<Organization[], []> {
        const [rawInvoke, loading, error] = useFetchGet<Organization[]>();
        const invoke = useCallback(() => rawInvoke('api/organization/resellers'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useManagers: () => {
        const [rawInvoke, loading, error] = useFetchGet<Manager[]>();
        const invoke = useCallback((orgId: string) => rawInvoke(`api/organization/${orgId}/managers`),[rawInvoke]);
        return [invoke, loading, error];
    },
    useCreateOtherContact: () => {
        const [rawInvoke, loading, error] = useFetchPost<Organization>();
        const invoke = useCallback(
            (orgId: string, model: CreateOrgOtherContact) => rawInvoke(`api/organization/${orgId}/otherContact`, model)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useUpdateOtherContact: () => {
        const [rawInvoke, loading, error] = useFetchPut<Organization>();
        const invoke = useCallback(
            (orgId: string, contactId: string, model: CreateOrgOtherContact) => 
                rawInvoke(`api/organization/${orgId}/otherContact/${contactId}`, model)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveOtherContact: () => {
        const [rawInvoke, loading, error] = useFetchDelete<Organization>();
        const invoke = useCallback(
            (orgId: string, contactId: string) => 
                rawInvoke(`api/organization/${orgId}/otherContact/${contactId}`)
        , [rawInvoke]);
        return [invoke, loading, error];
    },
    useDealRegOrgs: () => {
        const [rawInvoke, loading, error] = useFetchGet<DealRegOrg[]>();
        const invoke = useCallback(() => rawInvoke(`api/organization/withDealReg`), [rawInvoke]);
        return [invoke, loading, error];
    }
}