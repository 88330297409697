import { useMemo, useState } from "react";
import { EscapeRoomContext, EscapeRoomSettingsType } from "../../../api/Game/EscapeRoomContext"
import { EscapeRoomGame } from "../../../model/Game/EscapeRoom/EscapeRoomGame"
import EditGameComponent, { ExtraButtonProps } from "../../EditGameComponent"
import { isFetchError } from "../../../services/FetchHelper";
import EscapeRoomSettings from "./EscapeRoomSettings";


const EscapeRoom = () => {
    const [game, setGame] = useState<EscapeRoomGame>();
    const [create] = EscapeRoomContext.useCreate(); 
    const extraButtons = useMemo<ExtraButtonProps[]>(() => {
        if(!game){
            return [{id: 'create', hoverMsg: 'create', icon: "plus", action: () => create().then(x => !isFetchError(x) && setGame(x))}];
        }
        return [];
    },[game, create]);
    return (
        <EditGameComponent<EscapeRoomGame, EscapeRoomSettingsType>
            // contentClass={''}
            game={game}
            setGame={setGame}
            gameContext={EscapeRoomContext}
            settingsMapper={g => ({
                skin: g.skin ?? "Default", 
                document: g.document
            })}
            SettingsNode={EscapeRoomSettings}
            // PreviewNode={}
            // newGameElementFunction={newElement}
            newGameElementText={'sakura_item_create'}
            tempDescription={'escaperoom_text'}
            tempTitle={'escaperoom_title'}
            publishRequirements={[]}
            publicRequirements={[]}
            extraButtons={extraButtons}
        >

        </EditGameComponent>
    ); 
}

export default EscapeRoom; 