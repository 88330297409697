import React from 'react'
import { Map } from "../../../model/Game/Globetrotter/Map"
import { DbOrUploadImage } from '../../../services/ImageHelper';
import PinElement from './PinElement';
import { Location } from '../../../model/Game/Globetrotter/Location';
import MapMask from '../../../images/games/globetrotter/Gamemode_Map.png';
import MapOverlay from '../../../images/games/globetrotter/Gamemode_Map-overlay.png';
import ScaleLegend from './ScaleLegend';

interface Props {
    map: Map;
    onClick?: () => void;
    className?: string;
    hideLocation?: Location;
    showAsDisabled?: boolean;
    onLocationClicked?: (location: Location) => void;
}

const MapPreview = (props: Props) => {
    const {map, className, showAsDisabled, onLocationClicked, hideLocation} = props;
    
    if (!map) {
        return null;
    } 

    const handleMouseClick = (e: React.MouseEvent) => {
        if (!onLocationClicked) {
            return;
        }
        var x = e.nativeEvent.offsetX;
        var y = e.nativeEvent.offsetY;

        var clickedLocation = getLocationAtPos(x, y);
       
        if (clickedLocation) {
            onLocationClicked(clickedLocation);
        }
    }

    const getLocationAtPos = (x: number, y: number) : Location | undefined => {
        let closestDist = 10000;
        let closestLocation : Location | undefined;

        for (var i = 0; i < map.locations.length; i++) {
            const loc = map.locations[i];
            const dist = Math.sqrt(Math.abs(x - loc.x) * Math.abs(x - loc.x) + Math.abs(y - loc.y) * Math.abs(y - loc.y));
            if (dist < closestDist && dist <= loc.radius) {
                closestDist = dist;
                closestLocation = loc;
            }
        }

        return closestLocation;
    }
   

    return (
        <div onClick={handleMouseClick} className={`map-preview${className ? " " + className : ""}`}>
             <DbOrUploadImage className='background-image' image={map.mapImage} realSize style={{
                mask: `url(${MapMask})`,
                maskRepeat: "no-repeat" 
             }}/>
             <img src={MapOverlay} className='background-image' alt=''></img>
             { map.locations.filter(x => x.id !== hideLocation?.id).map((location) => 
                <PinElement location={location} showAsDisabled={showAsDisabled} placeHolderName='New location' />
              )
             }
             { map.scaleRatio > 0 && 
                <ScaleLegend scale={map.scaleRatio}/>
             }
        </div>
    )
}

export default MapPreview;