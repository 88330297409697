import React, { useContext, useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavMenuItem from "../Menu/NavMenuItem";
import { accountHasAnyPermission, accountIsAdmin } from '../../model/AccountType';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import { isOrganizationType } from '../../model/Organization';


const NavMenu = () => {
    const {me, myOrg} = useContext(SecureContext);

    const [showNav, setShowNav] = useState<boolean>(false);
    const toggleNav = () => setShowNav(old => !old);

    if(!me) return null;

    const isAdmin = accountIsAdmin(me);
    const isReseller = accountHasAnyPermission(me, "ChildOrganizations", "Admin");
    const showDeal = isAdmin || accountHasAnyPermission(me, "DealAdmin") || (isOrganizationType(myOrg) && !!myOrg.dealRegistrationContactId && accountHasAnyPermission(me, "Deal"));
    const showLicense = isAdmin || accountHasAnyPermission(me, "License");
    const showStats = isAdmin || isReseller || accountHasAnyPermission(me, 'Organization');

    const hideNav = () => setShowNav(false);

    return(
        <div className={`left-bar ${!showNav && 'hidden'}`}>
            <div className='link-container'>
                <NavMenuItem path={''} icon={'home'} onClick={hideNav} />
                <NavMenuItem path={'mygames'} icon={'gamepad'} onClick={hideNav} />
                <NavMenuItem path={'playlist'} icon={'list-ul'} onClick={hideNav} />
                <NavMenuItem path={'library'} icon={'archive'} onClick={hideNav} />
                <NavMenuItem path={'floor'} icon={'bars'} onClick={hideNav} />
                <NavMenuItem path={'users'} icon={'users'} onClick={hideNav} />
                <NavMenuItem path={'organization'} icon={'building'} onClick={hideNav} />
                {!isReseller && showLicense &&
                    <NavMenuItem path={'license'} icon={'bars'} onClick={hideNav} />
                }
                <NavMenuItem path={'tutorials'} icon={'chalkboard-teacher'} onClick={hideNav} />
                {!isReseller && showStats &&
                    <NavMenuItem path='stats' icon='chart-pie' onClick={hideNav} />
                }
                {!isReseller && showDeal &&
                    <NavMenuItem path='deal' icon='money-check' onClick={hideNav} />
                }
                {isReseller &&
                    <NavMenuItem 
                        path={'reseller'} 
                        icon={'user-tie'} 
                        onClick={hideNav}
                        subNav={[
                            showLicense ? {icon: 'bars', path: 'license'} : undefined,
                            showLicense ? {icon: 'project-diagram', path: 'multiuse'} : undefined,
                            showLicense ? {icon: 'warehouse', path: "storage"} : undefined,
                            {icon: 'desktop', path: 'units'},
                            {icon: 'flag', path: 'banner'},
                            showDeal ? {icon: 'money-check', path: 'deal'} : undefined,
                            showStats ? {icon: 'chart-pie', path: "stats"} : undefined,
                            isAdmin ? {icon: 'user-shield', path: 'admin'} : undefined,
                            isAdmin ? {icon: 'ticket', path: 'advertisement'} : undefined
                        ]} 
                    />
                }
            </div>
            <div className='toggle-menu' onClick={toggleNav}>
                <FontAwesomeIcon icon={showNav ? 'chevron-left' : 'chevron-right'} className='icon'/>
            </div>
        </div>
    );
};

export default NavMenu;