import { ReactNode, RefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translate from "../Helper/Translate";
import { SelectListOptions } from "../forms/FormGroup";
import { arrayToClassName, notUndefined } from "../../services/CustomFunctions";

export interface TableColumn<T>{
    id: string;
    header?: string|ReactNode;
    value: (t: T) => ReactNode;
    csvValue?: (t: T) => string;
    cmpValue?: (a: T) => string|number;
    tdClass?: string;
    filterValue?: (x: T) => string|boolean;
    filterDropDown?: SelectListOptions;
    hideHeader?: boolean;
    disableSort?: boolean;
    defaultFilter?: string|boolean;
    colSpan?: number;
}

export interface TableSort{
    value: string|undefined,
    asc: boolean
}

interface Props<T extends {id: string;}>{
    items: T[];
    columns: TableColumn<T>[]
    tableRef?: RefObject<HTMLTableElement>;
    filterNode?: ReactNode;
    sort: TableSort;

    setSort: (columnId: string) => void;
    onRowClick?: (item: T) => void;
    rowClass?: (x: T) => string|undefined; 
    nothingNode?: ReactNode;
}

const SimpleTable = <T extends {id: string}> (props: Props<T>) => {
    const {items, columns, tableRef, filterNode, setSort, sort, onRowClick, rowClass, nothingNode} = props;

    return(
        <table className={`simple-table`} ref={tableRef}>
            <thead>
                {filterNode}
                <tr>
                    {columns.map(({id, header: _header, hideHeader, disableSort, colSpan}) => {
                        const header = _header ?? id;
                        return(
                            <th 
                                key={id}
                                className={disableSort ? undefined : 'clickable'} 
                                onClick={disableSort ? undefined : () => setSort(id)}
                                colSpan={colSpan}
                            >
                                {!hideHeader && <>{sort.value === id ? <FontAwesomeIcon icon={'sort'} /> : ""} {typeof(header) === "string" ? <Translate id={header} /> : header }</>}
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {items.map(x => <ItemRow key={x.id} columns={columns} x={x} onRowClick={onRowClick} rowClass={rowClass} />)}
                {!items.length && <tr className='secondary'><td colSpan={columns.length}>{nothingNode ? nothingNode : <Translate id={'empty_list'} />}</td></tr>}
            </tbody>
        </table>
    )
}

export default SimpleTable;


interface ItemRowProps<T extends {id: string}>{
    x: T;
    columns: (TableColumn<T>)[]; 
    onRowClick?: (item: T) => void;
    rowClass?: (x: T) => string|undefined;
}

const ItemRow = <T extends {id: string}> (props: ItemRowProps<T>) => {
    const {x, onRowClick, rowClass, columns} = props;
    return(
        <tr 
            onClick={e => {e.stopPropagation(); onRowClick && onRowClick(x);}}
            className={arrayToClassName([onRowClick && 'clickable ', rowClass && rowClass(x)].filter(notUndefined))}
        >
            {columns.map(({id, value, tdClass}) => <td key={id} className={tdClass}>{value(x)}</td>)}
        </tr>
    )
}