import { useCallback } from "react";
import { GlobetrotterGame } from "../../model/Game/Globetrotter/GlobetrotterGame";
import { Hint } from "../../model/Game/Globetrotter/Hint";
import { Location } from "../../model/Game/Globetrotter/Location";
import { Map } from "../../model/Game/Globetrotter/Map";
import { ContextFunc, useFetchDelete, useFetchPost, useFetchPut } from "../../services/FetchHelper";
import { createBaseGameContext, GameSettings, IBaseGameContext } from "../BaseGameContext";
import { isDbAudio, isDbImage } from "../../services/ImageHelper";
import { createBaseSettingsFormData } from "../../services/FormDataHelper";

export interface GlobetrotterSettingsType extends GameSettings {
    locked: boolean;
}

interface IGlobetrotterContext extends IBaseGameContext<GlobetrotterGame> {
    useCreateMap: ContextFunc<GlobetrotterGame, [string | undefined, CreateMap]>;
    useUpdateMap: ContextFunc<GlobetrotterGame, [string, string, CreateMap]>;
    useDeleteMap: ContextFunc<GlobetrotterGame, [string, string]>;

    useCreateLocation: ContextFunc<GlobetrotterGame, [string, string, CreateLocation]>;
    useUpdateLocation: ContextFunc<GlobetrotterGame, [string, string, string, CreateLocation]>;
    useDeleteLocation: ContextFunc<GlobetrotterGame, [string, string, string]>;
    useBulkUpdateLocation: ContextFunc<GlobetrotterGame, [string, string, BulkUpdateLocation]>;

    useCreateHint: ContextFunc<GlobetrotterGame, [string, string, string, CreateHint]>;
    useUpdateHint: ContextFunc<GlobetrotterGame, [string, string, string, string, CreateHint]>;
    useDeleteHint: ContextFunc<GlobetrotterGame, [string, string, string, string]>;

    useUpdateSettings: ContextFunc<GlobetrotterGame, [string, GlobetrotterSettingsType]>
}

export interface CreateMap extends Map {
    reuseMapImage: boolean;
}
export interface CreateLocation extends Omit<Location, 'id'> {}
export interface CreateHint extends Omit<Hint, 'id'> {
    reuseImage: boolean;
    reuseSound: boolean;
}
export interface BulkUpdateLocation {
    updateLocations: Location[];
}

const route = "api/workshop/globetrotter";

export const GlobetrotterContext : IGlobetrotterContext = {
    ...createBaseGameContext("Globetrotter"),
    useCreateMap: () => {
        const [rawInvoke, loading, error] = useFetchPost<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string|undefined, model: CreateMap) => rawInvoke(gameId ? `${route}/${gameId}/map` : `${route}/map`, createMapModel(model)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useUpdateMap: () => {
        const [rawInvoke, loading, error] = useFetchPut<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, model: CreateMap) => rawInvoke(`${route}/${gameId}/map/${mapId}`, createMapModel(model)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useDeleteMap: () => {
        const [rawInvoke, loading, error] = useFetchDelete<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string) => rawInvoke(`${route}/${gameId}/map/${mapId}`),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },

    useCreateLocation: () => {
        const [rawInvoke, loading, error] = useFetchPost<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, model: CreateLocation) => rawInvoke(`${route}/${gameId}/map/${mapId}/location`, createLocationModel(model)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useUpdateLocation: () => {
        const [rawInvoke, loading, error] = useFetchPut<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, locationId: string, model: CreateLocation) => rawInvoke(`${route}/${gameId}/map/${mapId}/location/${locationId}`, createLocationModel(model)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useBulkUpdateLocation: () => {
        const [rawInvoke, loading, error] = useFetchPut<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, model: BulkUpdateLocation) => rawInvoke(`${route}/${gameId}/map/${mapId}/location/bulk`, createBulkUpdateLocationModel(model)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useDeleteLocation: () => {
        const [rawInvoke, loading, error] = useFetchDelete<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, locationId: string) => rawInvoke(`${route}/${gameId}/map/${mapId}/location/${locationId}`),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },

    useCreateHint: () => {
        const [rawInvoke, loading, error] = useFetchPost<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, locationId: string, model: CreateHint) => rawInvoke(`${route}/${gameId}/map/${mapId}/location/${locationId}`, createHintModel(model)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useUpdateHint: () => {
        const [rawInvoke, loading, error] = useFetchPut<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, locationId: string, hintId: string, model: CreateHint) => rawInvoke(`${route}/${gameId}/map/${mapId}/location/${locationId}/hint/${hintId}`, createHintModel(model)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },
    useDeleteHint: () => {
        const [rawInvoke, loading, error] = useFetchDelete<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, mapId: string, locationId: string, hintId: string) => rawInvoke(`${route}/${gameId}/map/${mapId}/location/${locationId}/hint/${hintId}`),
            [rawInvoke]
        );
        return [invoke, loading, error];
    },

    useUpdateSettings: () => {
        const [rawInvoke, loading, error] = useFetchPut<GlobetrotterGame>();
        const invoke = useCallback(
            (gameId: string, settings: GlobetrotterSettingsType) => rawInvoke(`${route}/${gameId}/settings`, settingsFormData(settings)),
            [rawInvoke]
        );
        return [invoke, loading, error];
    }
}

const createMapModel = (model: CreateMap) => ({
    ...model,
    reuseMapImage: isDbImage(model.mapImage),
    mapImage: isDbImage(model.mapImage) ? undefined : model.mapImage
});

const createLocationModel = (model: CreateLocation) => ({
    ...model
});

const createBulkUpdateLocationModel = (model: BulkUpdateLocation) => ({
    ...model
});

const createHintModel = (model: CreateHint) => ({
    ...model,
    reuseImage: isDbImage(model.image),
    image: isDbImage(model.image) ? undefined : model.image,
    reuseSound: isDbAudio(model.sound),
    sound: isDbAudio(model.sound) ? undefined : model.sound
});

const settingsFormData = (settings: GlobetrotterSettingsType): FormData => {
    let form = createBaseSettingsFormData(settings);
    form.append("Locked", JSON.stringify(settings.locked));
    return form;
}