import React from "react";
import { Location } from "../../../model/Game/Globetrotter/Location";
import Pin from '../../../images/games/globetrotter/Pin_blue.png';

interface Props {
    location: Location;
    placeHolderName?: string;
    showAsDisabled?: boolean;
    showAsActive?: boolean;
}

const PinElement = (props: Props) => {
    const { location, placeHolderName, showAsActive, showAsDisabled } = props;

    const model = { ...location, name: location.name.length === 0 && placeHolderName ? placeHolderName : location.name };

    return (
        <div className='pin-element'
            style={{
                top: `${model.y}px`,
                left: `${model.x}px`,
            }} >
            {model.radius > 0 &&
                <div className={`radius-circle${showAsDisabled ? " disabled" : ""}${showAsActive ? " active" : ""}`} style={{
                    width: `${model.radius * 2}px`,
                    height: `${model.radius * 2}px`,
                    left: `${model.radius * -1}px`,
                    top: `${model.radius * -1}px`,
                }} />
            }
            <img src={Pin} className='preview-pin' alt="" />
            <div className="pin-name">{model.name}</div>
            <div className={`pin-hints${model?.hints?.length > 0 ? "" : " empty"}`}>{model.hints.length} hints</div>

        </div>
    )
}

export default PinElement;