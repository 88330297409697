import { FormEvent, useContext, useEffect, useState } from "react";
import { RemoteConfigContext } from "../../../api/RemoteConfigContext";
import { CreateRemoteAppConfig, RemoteAppConfig } from "../../../model/Unit/RemoteAppConfig";
import { GeneralError } from "../../Error/GeneralError";
import { CheckBox, SelectList, SubmitButton } from "../../forms/FormGroup";
import { isFetchError } from "../../../services/FetchHelper";
import { GlobalModalContext } from "../../_MyFloor/MyFloorApp";
import { MinimalOrganization } from "../../../model/Response/MinimalOrganization";
import { FloorContext } from "../../../api/FloorContext";
import { OrganizationContext } from "../../../api/OrganizationContext";
import { Organization } from "../../../model/Organization";
import Translate from "../../Helper/Translate";
import OrganizationFormGroup from "../../forms/FormGroups/OrganizationFormGroup";
import { Loading } from "../../Loading";
import TextArea from "../../forms/FormGroups/TextAreaFormGroup";
import { localeFromLangCode, myfloorLocalizations } from "../../../model/LocalizationVariables";
import { PlatformType } from "../../../model/PlatformType";
import { WallContext } from "../../../api/WallContext";
import { License } from "../../../model/License";

interface Props{
    computerId: string;
    reseller?: MinimalOrganization;
    platformType: PlatformType;
    onUpdate?: (m: RemoteAppConfig) => void;
}

const RemoteConfigForm = (props: Props) => {
    const {computerId, reseller, platformType, onUpdate} = props;
    const {popMsg} = useContext(GlobalModalContext);
    
    const [getRemoteConfig, loadingRemoteConfig] = RemoteConfigContext.useGet();
    const [setRemoteConfig, loadingSet, errorSet] = RemoteConfigContext.useCreate(); 
    const [getFloors, loadingFloors] = FloorContext.useOrgFloors();
    const [getWalls, loadingWalls] = WallContext.useOrgWalls();
    const [getOrg, loadingOrg] = OrganizationContext.useOrganization();

    const loading = loadingRemoteConfig || loadingSet || loadingFloors || loadingWalls || loadingWalls || loadingOrg;

    const [model, setModel] = useState<CreateRemoteAppConfig>();
    const [licenses, setLicenses] = useState<License[]>([]);
    const [remoteConfigOrg, setRemoteConfigOrg] = useState<Organization>();

    useEffect(() => {
        getRemoteConfig(computerId).then(x => {
            if(isFetchError(x)){
                if(x.status === 404){
                    setModel({
                        useRemoteConfig: false,
                        locale: localeFromLangCode(reseller?.primaryLanguageCode),
                        organizationId: "",
                        apiKey: "",
                        isLicenseBlocked: false
                    });
                }
            }
            else{
                setModel(x);
                if(x.organizationId){
                    getOrg(x.organizationId).then(x => !isFetchError(x) && setRemoteConfigOrg(x));
                }
            }
        });   
    }, [getRemoteConfig, computerId, getOrg, reseller?.primaryLanguageCode]);

    useEffect(() => {
        const funcAsync = async () => {
            if(model?.organizationId){
                const licenses = platformType === PlatformType.Floor 
                    ? await getFloors(model.organizationId)
                    : await getWalls(model.organizationId);
                if(!isFetchError(licenses)){
                    setLicenses(licenses);
                    setModel(old => old ? ({...old, apiKey: old.apiKey || (licenses[0]?.apiKey ?? "")}) : old);
                }
             }
             else{
                 setLicenses([]);
             }
        }  
        funcAsync();
    }, [getFloors, getWalls, model?.organizationId, platformType])
    
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if(model){
            const result = await setRemoteConfig(computerId, {...model});
            if(!isFetchError(result)){
                popMsg("pop_change", "pop_remote_config_saved");
                if(onUpdate) onUpdate(result);
            }
        }
    }

    return(
        <>
            <GeneralError error={errorSet} />
            <div className='remote-config-form'>
                <Loading visible={loading} />
                <h2><Translate id='remote_config_form_title' /></h2>
                {model &&
                    <form onSubmit={onSubmit}>
                        <CheckBox 
                            label='remote_config_use' 
                            name={'useRemoteConfig'} 
                            active={model.useRemoteConfig} 
                            onChange={e => setModel({...model, useRemoteConfig: e.target.value})} 
                        />
                        {model.useRemoteConfig &&
                            <>
                                <OrganizationFormGroup
                                    label="license_org" 
                                    name='organizationId' 
                                    onChange={o => {setModel({...model, organizationId: o.id, apiKey: "", locale: localeFromLangCode(o.primaryLanguageCode)}); setRemoteConfigOrg(o)}} 
                                    value={remoteConfigOrg}
                                    errors={errorSet}
                                    filters={{
                                        withWalls: platformType === PlatformType.Wall || undefined, 
                                        withFloors: platformType === PlatformType.Floor || undefined
                                    }}  
                                />
                                <SelectList
                                    labelName={`license_${platformType.toLowerCase()}_name`}
                                    name={platformType.toLowerCase()}
                                    options={licenses.map(f => ({name: f.name, value: f.apiKey}))}
                                    onChange={e => setModel({...model, apiKey: e.target.value})}
                                    defaultValue={model.apiKey}
                                    noUnsavedChanges
                                />
                                <TextArea 
                                    name='apiKey' 
                                    value={model.apiKey} 
                                    onChange={x => setModel({...model, apiKey: x.target.value})}
                                    disabled
                                    noLabel  
                                    errors={errorSet}               
                                />
                                <SelectList 
                                    labelName='remote_config_locale'
                                    name='locale'
                                    options={myfloorLocalizations} 
                                    defaultValue={model.locale} 
                                    onChange={x => setModel({...model, locale: x.target.value})}                    
                                />
                                <CheckBox
                                    name="isLicenseBlocked"
                                    label="license_duplication_blocked"
                                    active={model.isLicenseBlocked}
                                    onChange={x => setModel(m => m && ({...m, isLicenseBlocked: x.target.value}))}
                                    errors={errorSet}
                                    errorKey="isLicenseBlocked"
                                />
                            </>
                        }
                        <SubmitButton />
                    </form>
                }
            </div>
        </>
    )
}

export default RemoteConfigForm;