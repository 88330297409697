import { useCallback } from "react";
import { ActiveFloorUnit } from "../model/ActiveFloorUnit";
import { ContextFunc, ContextReturn, useFetchGet, useFetchPost } from "../services/FetchHelper";
import { UnitTag } from "../model/Unit/UnitTag";
import { DuplicateLicenseInfo } from "../model/Response/DuplicatedLicenseInfo";

export interface IUnitContext{
    useAll: ContextFunc<ActiveFloorUnit[], []>;
    useRemove: ContextFunc<void, [string]>;
    useGetTags: ContextFunc<UnitTag[], []>;
    useAddTag: ContextFunc<UnitTag, [string, string]>;
    useRemoveTag: ContextFunc<UnitTag, [string, string]>;
    useSetNote: ContextFunc<ActiveFloorUnit, [string, string]>;
    useDuplicates: ContextFunc<DuplicateLicenseInfo[], []>;
    useDuplicatesMail: ContextFunc<void, [DuplicateLicenseInfo]>;
}

export const UnitContext: IUnitContext = {
    useAll: () => {
        const [rawInvoke, loading, error] = useFetchGet<ActiveFloorUnit[]>();
        const invoke = useCallback(() => rawInvoke('api/unit'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemove: () => {
        const [rawInvoke, loading, error] = useFetchPost<void>();
        const invoke = useCallback((id: string) => rawInvoke(`api/unit/status/delete`, { id }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useAddTag: function (): ContextReturn<UnitTag, [string, string]> {
        const [rawInvoke, loading, error] = useFetchPost<UnitTag>();
        const invoke = useCallback((unitId: string, tagName: string) => rawInvoke(`api/unit/addTag`, { unitId, tagName }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemoveTag: function (): ContextReturn<UnitTag, [string, string]> {
        const [rawInvoke, loading, error] = useFetchPost<UnitTag>();
        const invoke = useCallback((unitId: string, tagName: string) => rawInvoke(`api/unit/removeTag`, { unitId, tagName }), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGetTags: function (): ContextReturn<UnitTag[], []> {
        const [rawInvoke, loading, error] = useFetchGet<UnitTag[]>();
        const invoke = useCallback(() => rawInvoke(`api/unit/unitTags`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useSetNote: function (): ContextReturn<ActiveFloorUnit, [string, string]> {
        const [rawInvoke, loading, error] = useFetchPost<ActiveFloorUnit>();
        const invoke = useCallback((unitId: string, note: string) => rawInvoke('api/unit/setNote', {unitId, note}), [rawInvoke]);
        return [invoke, loading, error];
    },
    useDuplicates: () => {
        const [rawInvoke, loading, error] = useFetchGet<DuplicateLicenseInfo[]>();
        const invoke = useCallback(() => rawInvoke(`api/unit/duplicates`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useDuplicatesMail: () => {
        const [rawInvoke, loading, error] = useFetchPost<void>();
        const invoke = useCallback((info: DuplicateLicenseInfo) => rawInvoke(`api/unit/duplicates/mail`, info), [rawInvoke]);
        return [invoke, loading, error];
    }
}