import React, { useContext, useEffect, useState } from 'react';
import Translate from '../Helper/Translate';
import { LicenseAudit } from '../../model/License';
import moment, { Moment } from 'moment';
import { CheckBox } from '../forms/FormGroup';
import Dialog from '../Dialog';
import Sortable from '../DragnDrop';
import { LicenseContext } from '../../api/LicenseContext';
import { isFetchError } from '../../services/FetchHelper';
import { GeneralError } from '../Error/GeneralError';
import { useTranslation } from 'react-i18next';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import { accountIsAdmin } from '../../model/AccountType';
import { PaymentType } from '../../model/FinancialData';
import { dateHelper } from '../../services/DateHelper';
import { Organization } from '../../model/Organization';

interface Props{ 
    licenseId: string; 
    orgs: (Organization|undefined)[];
    onClose: () => void; 
}; 
type ListItem = {
    id: string; 
    date: string; 
    time: string; 
    editorEmail: string;
    action: string; 
    activatedDate: string; 
    installationDate: string; 
    reseller: string; 
    note: string; 
    resellerNote: string; 
    name: string; 
    expireDate: string;
    reactivationDate: string;
    paymentType: PaymentType;
    renewalOrder: string;
    price: string;
    paymentFrequency: string;
};

const LicenseHistory = (props: Props) => {
    const {licenseId, orgs, onClose} = props;
    const {me} = useContext(SecureContext);
    const isAdmin = accountIsAdmin(me); 
    const [licenseAudit, setLicenseAudit] = useState<LicenseAudit[]>([]); 
    const [getLicenseAudit, loadingGetLicenseAudit, errorGetLicenseAudit] = LicenseContext.useGetLicenseAudit(); 
    useEffect(() => {
        getLicenseAudit(licenseId).then(x => !isFetchError(x) && setLicenseAudit(x)); 
    }, [getLicenseAudit, licenseId]); 
    type DisplayOptions = (keyof ListItem)[];

    const defaultColumns: DisplayOptions = ['date', 'time','editorEmail', 'action']; 
    const _licenseFields: DisplayOptions = ['activatedDate', 'price', 'paymentFrequency', 'installationDate',
        'reseller', 'name', 'resellerNote', "expireDate", "reactivationDate", "renewalOrder", "paymentType"]; 
    const adminFields: DisplayOptions = ['note']; 
    const licenseFields = isAdmin ? [..._licenseFields, ...adminFields] : _licenseFields; 

    const [tempDisplayOptions, setTempDisplayOptions] = useState<DisplayOptions>(licenseFields);
    const setDisplayOptions = (opt: DisplayOptions) => {
        _setColumns([...defaultColumns, ...opt]);
        setTempDisplayOptions(opt);
    }; 

    const updateSort = (idToMove: string, destination: string)  => {
        const indexToMove = tempDisplayOptions.findIndex(x => x === idToMove);
        const destinationIndex = tempDisplayOptions.findIndex(x => x === destination);
        const currentArray = tempDisplayOptions ? [...tempDisplayOptions] : [];
        const itemToMove = currentArray[indexToMove];
        currentArray.splice(indexToMove, 1);
        currentArray.splice(destinationIndex, 0, itemToMove);
        setTempDisplayOptions(currentArray);
    };

    const sortCommit = () => {
        setDisplayOptions(tempDisplayOptions);
    }; 

    const renderEnable = (name: keyof ListItem) => {
        return(
            <div key={name} className={'enable-item'}>
                <CheckBox
                    name={`license_${name}`} 
                    active={!!tempDisplayOptions.find(x => x === name)} 
                    onChange={() => setDisplayOptions(tempDisplayOptions.find(x => x === name) ? tempDisplayOptions.filter(x => x !== name) : [...tempDisplayOptions, name])}
                    hideUnsaved 
                />
            </div>
        ); 
    };
    const {t} = useTranslation(); 

    const [columns, _setColumns] = useState([...defaultColumns, ...licenseFields]); 
    const getListItem = (audit: LicenseAudit): ListItem => {
        const ss = audit.snapshot;
        const fData = ss.financialData;
        return {
            id: audit.id,
            date: formatDate(moment.unix(audit.time)), 
            time: moment.unix(audit.time ?? 0).format('HH:mm'), 
            editorEmail: audit.editorEmail,
            action: audit.actionDisplayName ? t(`action_${audit.actionDisplayName}`) : audit.action, 
            activatedDate: ss.activatedDate ? formatDate(moment.unix(ss.activatedDate)) : "", 
            expireDate: fData?.expireDate ? formatDate(dateHelper.toDate(fData?.expireDate ?? "")) : "",
            installationDate: ss.installationDate ? formatDate(moment.unix(ss.installationDate)) : "", 
            reseller: orgs.find(x => x?.id === ss.resellerOrgId)?.systemName ?? ss.resellerOrgId ?? "", 
            note: ss.note, 
            resellerNote: ss.resellerNote, 
            name: ss.name,
            paymentType: t(`license_paymenttype_${fData?.paymentType ?? "Unknown"}`),
            reactivationDate: fData?.reactivationDate? formatDate(dateHelper.toDate(fData?.reactivationDate)) : "",
            price: `${fData?.price?.value ?? ""} ${fData?.price?.currency ?? ""}`,
            paymentFrequency: (fData?.paymentFrequency && fData?.paymentFrequency !== -2) ? fData?.paymentFrequency.toString() : "",
            renewalOrder: fData?.renewalOrder 
                ? t("license_renewal_order_string", {
                    po: fData?.renewalOrder.resellerPO, 
                    lineText: fData.renewalOrder.lineText,
                    start: formatDate(dateHelper.toDate(fData.renewalOrder.startDate)),
                    end: formatDate(dateHelper.toDate(fData.renewalOrder.endDate)),
                    invoiceDate: formatDate(dateHelper.toDate(fData.renewalOrder.desiredInvoiceDate)),
                })
                : ""
        };
    }; 

    return (
        <div className="license-history">
            <Dialog onClose={onClose} loading={ loadingGetLicenseAudit } >
                <div>
                    <div className={'enable-form'}>
                        {tempDisplayOptions.map(x => <Sortable className='gap' key={x} id={x} type={'sortable'} onSort={updateSort} onSortCommit={sortCommit} >{renderEnable(x)}</Sortable>)}
                    </div>
                    <div className={'enable-form'}> 
                        {licenseFields
                            .filter(x => !columns.includes(x))
                            .map(renderEnable)
                        }
                    </div>
                </div>
                <table className='simple-table'>
                    <thead>
                        <tr>{columns.map(x => <th key={x} className={licenseFields.includes(x) ? 'license-field' : ''}><Translate id={`license_${x}`}/></th>)}</tr>
                    </thead>
                    <tbody>
                        {licenseAudit.map(getListItem).map(listItem => 
                            <tr key={listItem.id} dir='auto'>
                                {columns.map(x => 
                                    <td className='hover-trigger truncate' key={x}>
                                        <span className='data-container'>
                                            {listItem[x]}
                                        </span>
                                        {['editorEmail', 'action', 'reseller', 'name', 'resellerNote', 'note', 'defaultTerms','renewalOrder'].includes(x) && 
                                            listItem[x].toString().length > 5 && 
                                                <span className='hover-msg'>
                                                    {listItem[x]}
                                                </span>
                                        }
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
                <GeneralError error={errorGetLicenseAudit} />       
            </Dialog>
        </div>
    );
}; 
const formatDate = (date?: Moment) =>
   date ? date.format('ll') : ""; 

export default LicenseHistory; 