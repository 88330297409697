import { errorStringsFromError } from "../../services/CustomFunctions";
import { FetchError, isFetchError } from "../../services/FetchHelper"
import Translate from "../Helper/Translate";
import FieldErrors from "./FieldErrors";

interface Props{
    errors: FetchError|undefined;
    label: string;
    errorKey: string;
}
const ErrorWithLabel = (props: Props) => {
    const {errors, label, errorKey} = props;
    const errorStrings = errorStringsFromError(isFetchError(errors) ? errors.body : errors, errorKey);
    if(!errorStrings) return null;

    return(
        <div className="form-group">
            <div className="label"><Translate id={label} /></div>
            <FieldErrors fieldname="LicenseRef" errors={errors} />
        </div>
    )
}

export default ErrorWithLabel;