import Dialog from "../../Dialog";
import { SubmitButton } from "../../forms/FormGroup";
import Translate from "../../Helper/Translate";
import { Location } from "../../../model/Game/Globetrotter/Location";
import { FormEvent, useContext, useState } from "react";
import InputFormGroup from "../../forms/FormGroups/InputFormGroup";
import { Map } from "../../../model/Game/Globetrotter/Map";
import MapPreview from "./MapPreview";
import MapInteraction from "./MapInteraction";
import { GlobetrotterContext } from "../../../api/Game/GlobetrotterContext";
import { GlobetrotterEditingContext } from "../../EditingContext";
import { isFetchError } from "../../../services/FetchHelper";
import { TextType, TextAlignment } from "../../../model/Game/TextType";
import { GeneralError } from "../../Error/GeneralError";

interface Props {
    map: Map;
    onClose: () => void;
}

const initialText: TextType = { content: "", fontColorHex: "#FFFFFF", fontFamily: "Secular One", fontSize: 34, textAlignment: TextAlignment.Center };

const initialLocation: Location = { name: "", hints: [], hintTitle: initialText, x: -100, y: -100, radius: 0, id: "" };

const AddLocationForm = (props: Props) => {
    const { map, onClose } = props;
    const [model, setModel] = useState<Location>(initialLocation);
    const [interact, setInteract] = useState<boolean>(true);

    const [createLocation, loadingCreate, errorCreate] = GlobetrotterContext.useCreateLocation();
    const { game, setGame } = useContext(GlobetrotterEditingContext);

    const [showStep2, setShowStep2] = useState<boolean>();

    const submitted = async (location: Location) => {
        if (map.id && game) {
            const result = await createLocation(game.id, map.id, location);

            if (!isFetchError(result)) {
                setGame(result);
            }
        }

        onClose();
    }

    const placedClick = (location: Location) => { 
        setModel({ ...model, x: location.x, y: location.y });
        setInteract(false);
        setShowStep2(true);
    }

    const mapModel = { ...map, locations: [...map.locations, model] };

    const updateName = (v: string) => {
        setModel(x => ({ ...x, name: v }));
    }

    const reset = () => {
        setShowStep2(false);
        setInteract(true);
        setModel(initialLocation);
    }

    return (
        <Dialog
            onClose={onClose}
            loading={loadingCreate}
        >
            <h1><Translate id="globetrotter_add_location" /></h1>
            <form className="globetrotter-map-form">
                <div className="flex">

                    <div>
                        <label><Translate id="globetrotter_map_preview" /></label>
                        <div className="map-edit">
                            <MapPreview className="map-static" map={mapModel} showAsDisabled></MapPreview>
                            {interact &&
                                <MapInteraction location={model} onPlaced={placedClick} />
                            }
                        </div>
                    </div>
                </div>
                <GeneralError error={errorCreate} />
            </form>
            {showStep2 && 
                <AddLocationFormStep2 map={map} onClose={reset} location={model} onSubmitted={submitted} onUpdated={updateName}/>
            }
        </Dialog>
    )
}

interface Step2Props {
    map: Map;
    onClose: () => void;
    location: Location;
    onSubmitted: (location: Location) => void;
    onUpdated: (name: string) => void;
}

const AddLocationFormStep2 = (props: Step2Props) => {
    const { onClose, location, onSubmitted, onUpdated } = props;
   
    const [name, setName] = useState<string>();

    const submitClick = async (e: FormEvent) => {
        e.preventDefault();
        name && onSubmitted({...location, name, radius: 50});
        onClose();
    };

    const updateName = (name: string) => {
        setName(name);
        onUpdated(name);
    }

    return (
        <Dialog onClose={onClose}>
            <form className="globetrotter-map-form" onSubmit={submitClick}>
                <div>
                    <InputFormGroup
                        name='locationName'
                        labelWidth100
                        label="globetrotter_location_name"
                        type='text'
                        value={name ?? ""}
                        placeholder='globetrotter_location_name'
                        onChange={(e) => updateName(e.target.value)}
                        tabIndex={1}
                    />
                </div>
                <SubmitButton split disabled={!name}/>
            </form>
        </Dialog >
    )
}

export default AddLocationForm;