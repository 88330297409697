import React from "react";

interface Props {
    scale: number;
}

const ScaleLegend = (props: Props) => {
    const { scale } = props;

    const display = (scale * 0.1).toFixed(scale % 10 === 0 ? 0 : 3);

    return (
        <div className="scale-legend">
            <span className="text white">{display} km</span>
            <div className="ruler white" />
            <div className="ruler" />
            <span className="text">{display} km</span>
        </div>
    );
}

export default ScaleLegend;