import MapPreview from "./MapPreview";
import { Map } from "../../../model/Game/Globetrotter/Map";
import { Button } from "../../forms/FormGroup";
import { useState } from "react";
import CreateMapForm from "./CreateMapForm";
import EditMapForm from "./EditMapForm";
import { GlobetrotterGame } from "../../../model/Game/Globetrotter/GlobetrotterGame";

interface Props {
    map: Map;
    game: GlobetrotterGame;
    onClick?: (map: Map) => void;
}

const MapControl = (props: Props) => {
    const { map, game } = props;

    const [showMapDetails, setShowMapDetails] = useState<boolean>();
    const [showEditMap, setShowEditMap] = useState<boolean>();

    return (
        <div>
            <h3>{map.mapName} ({map.locations.length} locations)</h3>
            <div className="flex">
                <Button onClick={() => setShowMapDetails(true)}>Edit map details</Button>
                <Button onClick={() => setShowEditMap(true)}>Edit locations</Button>
            </div>
            <MapPreview map={map} />
            {showMapDetails &&
                <CreateMapForm map={map} gameId={game.id} onClose={() => setShowMapDetails(false)} />
            }
            {showEditMap &&
                <EditMapForm
                    map={map}
                    onClose={() => setShowEditMap(false)}
                />
            }            
        </div>
    )
}

export default MapControl; 