import React from "react";
import { Hint } from "../../../model/Game/Globetrotter/Hint";
import HintPreview from "./HintPreview";
import { TextType } from "../../../model/Game/TextType";

interface Props {
    hint: Hint;
    hintTitle: TextType;
    onClick?: (hint: Hint) => void;
}

const HintItem = (props: Props) => {
    const { hint, hintTitle, onClick } = props;

    const clicked = () => {
        onClick && onClick(hint);
    }

    return (
        <div className="hint-item" onClick={clicked}>
            <HintPreview hint={hint} hintTitle={hintTitle} />
        </div>
    )
}
 
export default HintItem;