import { createContext, Dispatch, SetStateAction } from "react";
import { PlatformType } from "../model/PlatformType";
import { BaseGame } from "../model/Game/BaseGame";
import { GlobetrotterGame } from "../model/Game/Globetrotter/GlobetrotterGame";

export interface EditingContextProps{
    platform: PlatformType;
    setPlatform: Dispatch<SetStateAction<PlatformType>>; 
}

export interface EditingGameContextProps<T extends BaseGame> {
    game?: T;
    setGame: Dispatch<SetStateAction<T | undefined>>; 
}

export const EditingContext = createContext<EditingContextProps>({platform: PlatformType.Floor, setPlatform: () => {}});
export const GlobetrotterEditingContext = createContext<EditingGameContextProps<GlobetrotterGame>>({game: undefined, setGame: () => {}});

// Remove EditingContext.Provider from EditGameComponent and wrap the individual game components in and editing context as needed
// Split Props into two, one base and one generic for game