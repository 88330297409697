import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translate from "../Helper/Translate";
import { Fragment, ReactNode } from "react";

interface Props{
    info: {name: string, value: string|boolean|number|undefined|ReactNode}[];
}

const InfoTable = (props: Props) => {
    const {info} = props;
    return(
        <div className='simple-info-table'> 
            {info.map(x => 
                <div className='info' key={x.name}><span className='label'><Translate id={x.name} /></span>{valueRender(x.value)}</div>
            )}  
        </div>
    ) 
}

export default InfoTable;

const valueRender = (value: string|boolean|number|undefined|ReactNode): ReactNode => {
    switch (typeof value){
        case "boolean": return booleanRender(value);
        case "undefined": return <Fragment />;
        default: return value;
    }
}

const booleanRender = (value: boolean) => (
    <span>
        {value && <><FontAwesomeIcon icon={'check'} />&nbsp;</>}
        <Translate id={value ? "yes" : "no"} />
    </span>
)