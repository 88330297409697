import React, { useState } from "react";
import { ChangeEvent } from '../../../model/ChangeEvent';
import { Location } from "../../../model/Game/Globetrotter/Location";
import { Button, Slider } from "../../forms/FormGroup";
import { ModifiedLocation } from "../../../model/Game/Globetrotter/ModifiedLocation";

interface Props {
    location: Location;
    onClick?: (location: Location) => void;
    onMoveClick?: (location: Location) => void;
    isMoving: boolean;
    onRadiusChanged?: (location: Location, radius: number) => void;
    isHighlighted: boolean;
    modifiedLocation?: ModifiedLocation;
}

const LocationItem = (props: Props) => {
    const { location, modifiedLocation, onClick, onMoveClick, isMoving, onRadiusChanged, isHighlighted } = props;

    const [radius, setRadius] = useState<number>(location.radius);
    const loc = { ...location, ...modifiedLocation };

    const clicked = () => {
        onClick && onClick(location);
    }

    const moveClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onMoveClick && onMoveClick(location);
    }

    const noHints = !loc.hints || loc.hints.length <= 0;

    const changeRadius = (e: ChangeEvent<number>) => {
        setRadius(e.target.value);
        onRadiusChanged && onRadiusChanged(loc, e.target.value);
    }

    return (
        <>
            <div className={`location-item${noHints ? " warning" : ""}${isHighlighted ? " highlight" : ""}`} >
                {noHints &&
                    <label>Add at least one hint</label>
                }
                <div className="location-item-content" onClick={clicked}>
                    <div >
                        <h3> {loc.name}</h3>
                        {noHints ?
                            <div>
                                No hints
                            </div>
                            :
                            <div>
                                {loc.hints.length} hints
                            </div>
                        }

                    </div>
                    {onMoveClick &&
                        <div>
                            <Button onClick={moveClicked}>{isMoving ? "Stop" : "Move"}</Button>
                        </div>
                    }
                </div>
                {onRadiusChanged &&
                    <div className="location-radius">
                        <label>Radius:</label>
                        <Slider
                            name='radius'
                            value={radius}
                            min={10}
                            max={150}
                            step={2}
                            onChange={e => changeRadius(e)}
                        />
                    </div>
                }

            </div>

        </>
    )
}

export default LocationItem; 