import { useCallback, useEffect } from "react";
import { PlayListType } from "../model/PlayListType";
import myfloorEventHub from "./MyFloorEventHub";


const EventHub = myfloorEventHub;

const eventNames = {
    playlistUpdated: "playlist_updated",
    playlistDeleted: "playlist_deleted",
    playlistOverrideUpdated: "playlist_override_updated",
    playlistOverrideDeleted: "playlist_override_deleted"
}

export const EventDispatch = {
    dispatchPlaylistUpdated(...ps: PlayListType[]){
        for(const p of ps){
            EventHub.dispatchEvent(new CustomEvent(eventNames.playlistUpdated, {detail: p}));
        }
    },
    dispatchPlaylistDeleted(pId: string){
        EventHub.dispatchEvent(new CustomEvent(eventNames.playlistDeleted, {detail: pId}));
    },
    dispatchOverrideUpdate(playlistId: string, orgId: string){
        EventHub.dispatchEvent(new CustomEvent(eventNames.playlistOverrideUpdated, {detail: {playlistId, orgId}}));
    },
    dispatchOverrideDelete(playlistId: string, orgId: string){
        EventHub.dispatchEvent(new CustomEvent(eventNames.playlistOverrideDeleted, {detail: {playlistId, orgId}}));
    }
}

export const EventEffects = {
    usePlaylistUpdatedEffect: (listener: (p: PlayListType) => void, dependecies: unknown[]) => {
        const callBack = useCallback((e: CustomEvent<PlayListType>) => listener(e.detail)
        , dependecies);// eslint-disable-line
    
        useEffect(() => {
            EventHub.addEventListener(eventNames.playlistUpdated, callBack);
            return () => EventHub.removeEventListener(eventNames.playlistUpdated, callBack);
        }, [callBack]);
    },
    usePlaylistDeletedEffect: (listener: (pId: string) => void, dependecies: unknown[]) => {
        const callBack = useCallback((e: CustomEvent<string>) => listener(e.detail)
        , dependecies);// eslint-disable-line
        useEffect(() => {
            EventHub.addEventListener(eventNames.playlistDeleted, callBack);
            return () => EventHub.removeEventListener(eventNames.playlistDeleted, callBack);
        }, [callBack]);
    },
    usePlaylistOverrideUpdate: (listener: (params: {playlistId: string, orgId: string}) => void, dependecies: unknown[]) => {
        const callBack = useCallback((e: CustomEvent<{playlistId: string, orgId: string}>) => listener(e.detail)
        , dependecies); // eslint-disable-line
        useEffect(() => {
            EventHub.addEventListener(eventNames.playlistOverrideUpdated, callBack);
            return  () => EventHub.removeEventListener(eventNames.playlistOverrideUpdated, callBack);
        }, [callBack]);
    },
    usePlaylistOverrideDeleted: (listener: (params: {playlistId: string, orgId: string}) => void, dependecies: unknown[]) => {
        const callBack = useCallback((e: CustomEvent<{playlistId: string, orgId: string}>) => listener(e.detail)
        , dependecies); // eslint-disable-line
        useEffect(() => {
            EventHub.addEventListener(eventNames.playlistOverrideDeleted, callBack);
            return  () => EventHub.removeEventListener(eventNames.playlistOverrideDeleted, callBack);
        }, [callBack]);
    }
}