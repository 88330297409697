import { useCallback } from "react";
import { EscapeRoomGame } from "../../model/Game/EscapeRoom/EscapeRoomGame";
import { ContextFunc, useFetchPut } from "../../services/FetchHelper";
import { createBaseGameContext, GameSettings, IBaseGameContext } from "../BaseGameContext";
import { createBaseSettingsFormData } from "../../services/FormDataHelper";


export interface EscapeRoomSettingsType extends GameSettings{}
interface IEscapeRoomContext extends IBaseGameContext<EscapeRoomGame>{
    useUpdateSettings: ContextFunc<EscapeRoomGame, [string, GameSettings]>;
}
const baseUrl = 'api/workshop/escaperoom'; 

export const EscapeRoomContext: IEscapeRoomContext = {
    ...createBaseGameContext('EscapeRoom'),
    useUpdateSettings: () => {
        const [rawInvoke, loading, error] = useFetchPut<EscapeRoomGame>();
        const invoke = useCallback(
            (gameId: string, settings: GameSettings) => 
                rawInvoke(`${baseUrl}/${gameId}/settings`, createBaseSettingsFormData(settings))
            , [rawInvoke]
        );
        return [invoke, loading, error];
    }
}