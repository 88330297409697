import React, { FormEvent, useContext, useEffect, useState } from 'react';
import Dialog from "../../Dialog";
import { CheckBox, SelectLanguage, SelectList, SubmitButton} from "../FormGroup";
import {accountHasAnyPermission, AccountType, getFullName} from "../../../model/AccountType";
import ConfirmationForm from "../games/ConfirmationForm";
import { EditOrganization, isResellerOrg, newOrganiztion, Organization} from "../../../model/Organization";
import InputFormGroup from '../FormGroups/InputFormGroup';
import VolumePriceFormGroup from '../FormGroups/VolumePriceFormGroup';
import { GlobalModalContext, SecureContext } from '../../_MyFloor/MyFloorApp';
import { isFetchError } from '../../../services/FetchHelper';
import Translate from '../../Helper/Translate';
import { OrganizationContext } from '../../../api/OrganizationContext';
import OrgCategoryPicker from '../../Organization/OrgCategoryPicker';
import { getToken } from '../../../services/LocalStorageService';
import { AccountContext } from '../../../api/AccountContext';
import { SortByFirstName } from '../../../services/SortFunctions';

interface Props{
    organization: Organization|null;
    orgCreated: (o: Organization) => void;
    orgUpdated: (o: Organization) => void;
    orgRemoved?: () => void;
    onClose: () => void;
}

const OrganizationForm = (props: Props) => {
    const {organization, onClose, orgCreated, orgUpdated, orgRemoved} = props;
    const {me, myOrg} = useContext(SecureContext);
    const isAdmin = accountHasAnyPermission(me, 'Admin');
    const {popErrorMsg, popMsg} = useContext(GlobalModalContext);
    const [confirmRemove, setConfirmRemove] = useState(false);
    const [dealAdmins, setDealAdmins] = useState<AccountType[]>([]);
    const [model, setModel] = useState<EditOrganization>(organization ? 
        {...organization, createContactAccount: false} : 
        newOrganiztion
    );
    const [contactEmailEdited, setContactEmailEdited] = useState(false); 
    if (model.contactEmail !== organization?.contactEmail && !contactEmailEdited) {
        setContactEmailEdited(true); 
        setModel(m => ({...m, createContactAccount: true})); 
    }
    const [getDealAdmins, loadingDealAdmins] = AccountContext.useDealAdmins();
    const [update, loadingUpdate, errorUpdate] = OrganizationContext.useUpdate();
    const [create, loadingCreate, errorCreate] = OrganizationContext.useCreate();
    const [remove, loadingRemove, errorRemove] = OrganizationContext.useDelete();
    
    const errors = errorUpdate || errorCreate || errorRemove;
    
    useEffect(() => {
        if(accountHasAnyPermission(me, 'Admin')){
            getDealAdmins().then(x => !isFetchError(x) && setDealAdmins(x.sort(SortByFirstName)));
        } 
    }, [me, getDealAdmins]);

    if(!accountHasAnyPermission(me, "Admin", "ChildOrganizations", "Organization", "License")) return null;


    const isReseller = !!(accountHasAnyPermission(me, 'ChildOrganizations', 'License') && isResellerOrg(myOrg));
    const isCreatingReseller = model.type.toLowerCase() === "reseller" || model.categories.includes("SalesPartner");

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if(model.id){
            const result = await update(model)
            if(!isFetchError(result)){
                orgUpdated(result.organization);
                if(result.createdAccount){
                    popMsg("pop_added", "pop_msg_user_created", { email: result.createdAccount.email });
                }
                else if(model.createContactAccount){
                    popMsg("pop_msg_error", "pop_msg_user_not_created", { email: model.contactEmail });
                }
                onClose();
            }
            else{
                popErrorMsg(result);
            }
        }
        else{
            const result = await create(model);
            if(!isFetchError(result)){
                orgCreated(result.organization);
                if(result.createdAccount){
                    popMsg("pop_added", "pop_msg_user_created", { email: result.createdAccount.email });
                }
                else if(model.createContactAccount){
                    popMsg("pop_msg_error", "pop_msg_user_not_created", { email: model.contactEmail });
                }
                onClose();
            }
            else{
                popErrorMsg(result);
            }
        }
    }

    return(
        <Dialog
            onClose={onClose}
            loading={loadingCreate||loadingUpdate||loadingRemove||loadingDealAdmins}
            icon={organization ? 'trash' : undefined}
            onIconClick={() => setConfirmRemove(true)}
            className='org-form'
        >
            <h1><Translate id={model.id ? 'organization_title' : 'organization_create_title'}/></h1>
            <form onSubmit={onSubmit}>
                <div className='flex gap'>
                    <div className='base-info'>
                        <InputFormGroup
                            name='name' 
                            placeholder='name' 
                            type='text' 
                            value={model.name} 
                            onChange={e => setModel(m => ({...m, name: e.target.value}))}
                            errors={errors} 
                            labelWidth100
                        />
                        {(isReseller || isAdmin) && 
                            <>
                                <InputFormGroup
                                    name='systemName' 
                                    placeholder='systemName' 
                                    type='text' 
                                    value={model.systemName} 
                                    onChange={e => setModel(m => ({...m, systemName: e.target.value}))} 
                                    errors={errors} 
                                    labelWidth100
                                />
                                <InputFormGroup 
                                    name='type' 
                                    placeholder='type' 
                                    type='text' 
                                    value={model.type} 
                                    onChange={e => setModel(m => ({...m, type: e.target.value}))} 
                                    errors={errors} 
                                    labelWidth100
                                />
                                <InputFormGroup 
                                    name='country' 
                                    placeholder='country' 
                                    type='text' 
                                    value={model.country} 
                                    onChange={e => setModel(m => ({...m, country: e.target.value}))} 
                                    errors={errors} 
                                    labelWidth100
                                />
                                <InputFormGroup 
                                    name='state' 
                                    placeholder='state' 
                                    type='text' 
                                    value={model.state} 
                                    onChange={e => setModel(m => ({...m, state: e.target.value}))} 
                                    errors={errors} 
                                    labelWidth100
                                />
                                <InputFormGroup 
                                    name='city' 
                                    placeholder='city' 
                                    type='text' 
                                    value={model.city} 
                                    onChange={e => setModel(m => ({...m, city: e.target.value}))} 
                                    errors={errors} 
                                    labelWidth100
                                />
                                <SelectLanguage
                                    name={'primaryLanguageCode'} 
                                    onChange={e => setModel(m => ({...m, primaryLanguageCode: e.target.value}))}
                                    defaultValue={model.primaryLanguageCode}   
                                    labelWidth100                       
                                />
                                <OrgCategoryPicker 
                                    value={model.categories}
                                    onChange={categories => setModel(old => ({...old, categories}))}
                                    autoSchool
                                />
                            </>
                        }
                    </div>
                    <div className='contact-info'>
                        <h2><Translate id='contact_general' /></h2>
                        <InputFormGroup 
                            name='contactName' 
                            placeholder='contactName' 
                            type='text' 
                            value={model.contactName} 
                            onChange={e => setModel(m => ({...m, contactName: e.target.value}))} 
                            errors={errors} 
                            labelWidth100
                        />
                        <InputFormGroup 
                            name='contactEmail' 
                            placeholder='contactEmail' 
                            type={'text'}
                            value={model.contactEmail} 
                            onChange={e => setModel(m => ({...m, contactEmail: e.target.value}))} 
                            errors={errors} 
                            labelWidth100
                        />
                        <CheckBox
                            name='createContactAccount'
                            label='create_contact_account'
                            active={model.createContactAccount}
                            onChange={e => setModel(m => ({...m, createContactAccount: e.target.value}))}
                            hoverMsg='create_contact_account_explain'
                        />
                        <InputFormGroup 
                            name='contactPhone' 
                            placeholder='contactPhone' 
                            type='text' 
                            value={model.contactPhone} 
                            onChange={e => setModel(m => ({...m, contactPhone: e.target.value}))} 
                            errors={errors} 
                            labelWidth100
                        />
                        {isCreatingReseller &&
                            <>
                                <InputFormGroup
                                    name='supportEmail'
                                    label='org_support_email'
                                    type='text'
                                    value={model.supportEmail ?? ""}
                                    onChange={e => setModel(m => ({...m, supportEmail: e.target.value}))}
                                    errors={errors}
                                />
                                <InputFormGroup
                                    name='supportPhone'
                                    label='org_support_phone'
                                    type='text'
                                    value={model.supportPhone ?? ""}
                                    onChange={e => setModel(m => ({...m, supportPhone: e.target.value}))}
                                    errors={errors}
                                />
                            </>
                        }    
                        <div className='well padding-s'>
                            <h2><Translate id='contact_invoice' /></h2>
                            <p><Translate id={'invoice_contact_explain'} /></p>
                            <InputFormGroup 
                                name='invoiceContactName' 
                                label='invoice_contact_name' 
                                type='text' 
                                value={model.invoiceContactName ?? ""} 
                                onChange={e => setModel(m => ({...m, invoiceContactName: e.target.value}))} 
                                errors={errors} 
                                labelWidth100
                            />
                            <InputFormGroup 
                                name='invoiceContactEmail' 
                                label='invoice_contact_email' 
                                type='email' 
                                value={model.invoiceContactEmail ?? ""} 
                                onChange={e => setModel(m => ({...m, invoiceContactEmail: e.target.value}))} 
                                errors={errors} 
                                labelWidth100
                            />
                        </div>
                        {isCreatingReseller &&
                            <div className='well padding-s'>
                                <h2><Translate id='contact_license' /></h2>
                                <p><Translate id={'license_contact_explain'} /></p>
                                <InputFormGroup 
                                    name='licenseContactName' 
                                    label='license_contact_name' 
                                    type='text' 
                                    value={model.licenseContactName ?? ""} 
                                    onChange={e => setModel(m => ({...m, licenseContactName: e.target.value}))} 
                                    errors={errors} 
                                    labelWidth100
                                />
                                <InputFormGroup 
                                    name='licenseContactEmail' 
                                    label='license_contact_email' 
                                    type='email' 
                                    value={model.licenseContactEmail ?? ""} 
                                    onChange={e => setModel(m => ({...m, licenseContactEmail: e.target.value}))} 
                                    errors={errors} 
                                    labelWidth100
                                />
                            </div>
                        }
                    </div>
                    {(isCreatingReseller || isAdmin) &&
                        <div className='finansial-info'>
                            <h2><Translate id='financial_info' /></h2>
                            {isCreatingReseller &&
                                <>
                                    <CheckBox 
                                        name={'allowCustomerAutoRenew'} 
                                        label='allow_customer_auto_renew'
                                        active={model.allowCustomerAutoRenew}
                                        onChange={e => setModel(m => ({...m, allowCustomerAutoRenew: e.target.value}))}
                                        hoverMsg='allow_customer_auto_renew_explain' 
                                    />
                                    {isAdmin &&
                                        <>
                                            <CheckBox
                                                name='allowRenewFreeStartDate'
                                                label='allow_renew_free_start_date'
                                                active={model.allowRenewFreeStartDate}
                                                onChange={e => setModel(m => ({...m, allowRenewFreeStartDate: e.target.value}))}
                                                hoverMsg='allow_renew_free_start_date_explain'
                                            />
                                            <CheckBox
                                                name='useOrgPricesOverLicense'
                                                label='use_org_prices_over_license'
                                                active={model.useOrgPricesOverLicense}
                                                onChange={e => setModel(m => ({...m, useOrgPricesOverLicense: e.target.value}))}
                                                hoverMsg='use_org_prices_over_license_explain'
                                            />
                                        </>
                                    }
                                </>
                            }
                            {isCreatingReseller && isAdmin && 
                                <>
                                    <h2>Price information</h2>
                                    <p>
                                        Used as default information when creating new licenses.<br/>
                                        Not effect the invoices generated, edit the license to effect invoices!
                                    </p>
                                    <div className='reseller-price-form'>
                                        <VolumePriceFormGroup
                                            name='baseFloorPrices'
                                            label={'base_floor_price'}
                                            value={model.baseFloorPrices || []}
                                            onChange={ps => setModel(m => ({...m, baseFloorPrices: ps}))}
                                        />
                                    </div>
                                    <div className='reseller-price-form'>
                                        <VolumePriceFormGroup
                                            name='baseWallPrices'
                                            label={'base_wall_price'}
                                            value={model.baseWallPrices || []}
                                            onChange={ps => setModel(m => ({...m, baseWallPrices: ps}))}
                                        />
                                    </div>
                                </>
                            }
                            {isAdmin && 
                                <>
                                    <InputFormGroup
                                        label={'economic_ref'}
                                        name='economicCustomerNumber'
                                        value={model.economicCustomerNumber ?? ''}
                                        onChange={e => setModel(m => ({...m, economicCustomerNumber: e.target.value}))}
                                        type={'text'}
                                        disabled={!isAdmin}
                                        errors={errors} 
                                        labelWidth100
                                    />
                                    <InputFormGroup 
                                        label={'invoice_heading'}
                                        name={'invoiceHeading'}
                                        value={model.invoiceHeading || ''}
                                        onChange={e => setModel(m => ({...m, invoiceHeading: e.target.value}))}
                                        type={'text'}
                                        disabled={!isAdmin}
                                        errors={errors} 
                                        labelWidth100
                                    />
                                </>
                            }
                        </div>
                    }
                </div>
                <div className='deal-registration'>
                    {isAdmin && dealAdmins.length && isCreatingReseller &&
                        <SelectList
                            labelName={'deal_registration_contact'}
                            name='dealRegistrationContactId'
                            options={[
                                {name: 'deal_registration_no_use', value: '', translate: true},
                                ...dealAdmins.map(x => ({name: `${getFullName(x)} (${x.email})`, value: x.id}))
                            ]}
                            defaultValue={model.dealRegistrationContactId}
                            onChange={e => setModel(m => ({...m, dealRegistrationContactId: e.target.value}))}
                        />
                    }
                    {isCreatingReseller && 
                        <CheckBox
                            active={model.isTrendingGamesHidden}
                            onChange={e => setModel(o => ({...o, isTrendingGamesHidden: e.target.value}))}
                            name='org_is_trending_games_hidden'
                            hoverMsg='org_is_trending_games_hidden_explain'
                        />
                    }
                </div>
                <SubmitButton split />
                {errors && errors.status === 401 && <div className='form-error'><Translate id={getToken() ? "unauthorized_error" : "login_expired" } /></div>}
            </form>
            {model.id && confirmRemove &&
                <ConfirmationForm
                    text={"organization_remove_confirm"}
                    confirmText={"yes"}
                    cancelText={"no"}
                    onConfirm={() => remove(model.id).then(x => {
                        if(!isFetchError(x)){
                            orgRemoved && orgRemoved(); 
                            onClose();
                        }
                    })}
                    closeOnConfirm
                    onCancel={() => setConfirmRemove(false)}
                />
            }
           
        </Dialog>
    )
};


export default OrganizationForm;