import { FormEvent, useEffect, useState } from "react";
import { License, LicenseWarningState } from "../../../model/License";
import Dialog from "../../Dialog";
import { AdminUpdateLicense } from "../../../model/Request/ActiveFloorLicense/AdminUpdateLicense";
import { AdminCreateFloor } from "../../../model/Request/ActiveFloorLicense/AdminCreateFloor";
import { AdminCreateWall } from "../../../model/Request/ActiveFloorLicense/AdminCreateWall";
import { Floor } from "../../../model/Floor";
import { Wall } from "../../../model/Wall";
import { PlatformType, platformTypes } from "../../../model/PlatformType";
import { FetchError, isFetchError } from "../../../services/FetchHelper";
import moment from "moment";
import { LicenseContext } from "../../../api/LicenseContext";
import ConfirmationForm, { useConfirmDeleteAsync } from "../../forms/games/ConfirmationForm";
import { Organization, getPriceForFrequency } from "../../../model/Organization";
import OrganizationFormGroup from "../../forms/FormGroups/OrganizationFormGroup";
import { OrganizationContext } from "../../../api/OrganizationContext";
import { CheckBox, SelectList, SubmitButton } from "../../forms/FormGroup";
import InputFormGroup, { DelayedInputFormGroup } from "../../forms/FormGroups/InputFormGroup";
import Translate from "../../Helper/Translate";
import { GeneralError } from "../../Error/GeneralError";
import { useTranslation } from "react-i18next";
import DatePickerFormGroup from "../../forms/FormGroups/DatePickerFormGroup";
import PriceFormGroup from "../../forms/FormGroups/PriceFormGroup";
import { Currency, Price, equalPrice } from "../../../model/Price";
import TextAreaFormGroup from "../../forms/FormGroups/TextAreaFormGroup";
import LicenseHistory from "../LicenseHistory";
import OrganizationForm from "../../forms/Organization/OrganizationForm";
import useTemplates from "./useTemplates";
import { ChangeEvent } from "../../../model/ChangeEvent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminUpdateWall } from "../../../model/Request/ActiveFloorLicense/AdminUpdateWall";
import { PaymentType, paymentTypes } from "../../../model/FinancialData";
import { dateHelper } from "../../../services/DateHelper";
import { RadioGroup } from "../../forms/RadioGroup";

interface Props{
    license: Floor|Wall|null;
    onClose: () => void;
    onDeleted: () => void;
    onUpdated: (l: License, endClient: Organization, invoicedOrg: Organization) => void;
    onCreated: (l: License, endClient: Organization, invoicedOrg: Organization) => void;
}

type FormLicenseModel = AdminUpdateLicense&AdminUpdateWall&AdminCreateFloor&AdminCreateWall&{platformType: PlatformType};

const defaultPrice = {value: 9999, currency: Currency.EUR};

export const licenseToModel = (license: Floor|Wall): FormLicenseModel => ({
    orgId: license.organizationId,
    resellerId: license.resellerOrgId,
    resellerErpRef: "",
    useTemplateScheduledActions: true,
    isRtl: (license as Wall).isRightToLeftFlow,
    activatedDate: license.activatedDate,
    expireDate: license.financialData?.expireDate ?? "",
    installationDate: license.installationDate,
    name: license.name,
    note: license.note,
    paymentFrequency: license.financialData?.paymentFrequency ?? 0,
    paymentType: license.financialData?.paymentType ?? "Unknown",
    platformType: license.platformType,
    prepaidMonths: license.financialData?.prepaidMonths ?? 0,
    price: license.financialData?.price ?? defaultPrice,
    reactivationDate: license.financialData?.reactivationDate,
    resellerNote: license.resellerNote,
    licenseWarningState: license.licenseWarningState,
    allowedNumberOfUnits: license.allowedNumberOfUnits
})

const formModelForNew: FormLicenseModel = {
    platformType: PlatformType.Floor,
    orgId: undefined,
    resellerId: undefined,
    resellerErpRef: undefined,
    name: "ActiveFloor",
    note: "",
    resellerNote: "",
    templateKey: "",
    useTemplateScheduledActions: true,
    price: defaultPrice,
    paymentFrequency: 12,
    prepaidMonths: 12,
    
    paymentType: "AutoRenew",
    expireDate: "",
    reactivationDate: "",
    activatedDate: 0,
    installationDate: 0,
    
    isRtl: false,
    licenseWarningState: "Normal",
    allowedNumberOfUnits: 1
}

const formModelToRequestModel = (m: FormLicenseModel): FormLicenseModel => ({
    ...m,
    price: {...m.price, value: m.paymentType === "Free" ? 0 : m.price.value} 
})

const AdminLicenseForm = (props: Props) => {
    const {license, onClose, onDeleted: _onDeleted, onCreated: _onCreated, onUpdated: _onUpdated} = props;
    const onDeleted = () => {onClose(); _onDeleted();};
    const onCreated = (l: License, o: Organization, r: Organization) => {onClose(); _onCreated(l, o, r);};
    const onUpdated = (l: License, o: Organization, r: Organization) => {onClose(); _onUpdated(l, o, r);};
    const [showLicenseHistory, setShowLicenseHistory] = useState(false); 

    const {t} = useTranslation();

    const [getOrg, loadingOrg, errorOrg] = OrganizationContext.useOrganization();
    const [getReseller, loadingReseller, errorReseller] = OrganizationContext.useOrganization()
    const [removeLicense, loadingRemove, errorRemove] = LicenseContext.useRemove();

    const [createFloor, loadingCreateFloor, errorCreateFloor] = LicenseContext.useCreateFloorAdmin();
    const [createWall, loadingCreateWall, errorCreateWall] = LicenseContext.useCreateWallAdmin();
    const [updateFloor, loadingUpdateFloor, errorUpdateFloor] = LicenseContext.useUpdateFloorAdmin();
    const [updateWall, loadingUpdateWall, errorUpdateWall] = LicenseContext.useUpdateWallAdmin();
    const [templates, recalcTemplates, loadingTemplates] = useTemplates();

    useEffect(() => {
        if(license){
            getOrg(license.organizationId).then(o => {
                if(!isFetchError(o)){
                    if(license.organizationId === license.resellerOrgId) _setReseller(o);
                    _setOrg(o);
                }
            });
            if(license.resellerOrgId && license.organizationId !== license.resellerOrgId){
                getReseller(license.resellerOrgId).then(x => !isFetchError(x) && _setReseller(x));
            }
        } 
    }, [license, getOrg, getReseller]);

    const loading = loadingOrg||loadingReseller||loadingRemove||loadingCreateFloor||loadingCreateWall||loadingUpdateFloor||loadingUpdateWall||loadingTemplates;
    const generalErrors = errorOrg||errorReseller||errorRemove||errorCreateFloor||errorCreateWall||errorUpdateFloor||errorUpdateWall;
    const formErrors = errorCreateFloor||errorCreateWall||errorUpdateFloor||errorUpdateWall;

    const [org, _setOrg] = useState<Organization>();
    const [reseller, _setReseller] = useState<Organization>();
    const [createEndClient, setCreateEndClient] = useState(false);
    const [confirmPrice, setConfirmPrice] = useState<Price>();
    const [model, setModel] = useState<FormLicenseModel>(license ? licenseToModel(license) : formModelForNew);

    const [confirmRemoveDialog, showConfirmRemove] = useConfirmDeleteAsync(license && (() => removeLicense(license?.id, license?.platformType)), onDeleted);

    const recalcPrice = (paymentFrequency: number|undefined, platformType: PlatformType, reseller: Organization|undefined) => {
        if(!paymentFrequency || !platformType || !reseller) return;
        const newPrice = getPriceForFrequency(reseller, paymentFrequency, platformType);
        if(newPrice && !equalPrice(newPrice, model.price)){
            setConfirmPrice(newPrice);
        }   
    };

    const setOrg = (o: Organization) => {
        _setOrg(o); 
        if(!reseller){
            setReseller(o);
        }
        setModel(x => x && ({...x, orgId: o.id, resellerId: !reseller ? o.id : x.resellerId}));
    };
    
    const setReseller = async (o: Organization) => {   
        _setReseller(o);
        setModel(x => ({
            ...x, 
            resellerId: o.id, 
            resellerErpRef: o.economicCustomerNumber
        }));
        recalcTemplates(model.platformType, o);
        recalcPrice(model.paymentFrequency, model.platformType, o);
    }

    const setPlatformType = (e: ChangeEvent<string>) =>{
        const platformType = e.target.value as PlatformType;
        const changeName = !model.name || model.name === t(`license_${model.platformType.toLowerCase()}_name`);
        setModel(m => m && ({
            ...m, 
            platformType, 
            name: changeName ? t(`license_${e.target.value.toLowerCase()}_name`) : m.name
        }));
        recalcPrice(model?.paymentFrequency, platformType, reseller);
        recalcTemplates(platformType, reseller);
    }

    const createLicense = async () => {
        if(!org || !reseller) return;
        let result: FetchError|License|undefined = undefined;
        switch(model.platformType){
            case PlatformType.Floor:
                result = await createFloor(formModelToRequestModel(model));
                break;
            case PlatformType.Wall:
                result = await createWall(formModelToRequestModel(model));
                break;
            default: return;
        }
        if(result && !isFetchError(result)){
            onCreated(result, org, reseller);
            onClose();
        }
    }

    const updateLicense = async (id: string) => {
        if(!org || !reseller) return;
        let result: FetchError|License|undefined = undefined;
        switch(model.platformType){
            case PlatformType.Floor:
                result = await updateFloor(id, formModelToRequestModel(model));
                break;
            case PlatformType.Wall:
                result = await updateWall(id, formModelToRequestModel(model));
                break;
            default: return;
        }
        if(result && !isFetchError(result)){
            onUpdated(result, org, reseller);
            onClose();
        }
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(isCreateForm) createLicense();
        else if(isUpdateForm) updateLicense(license.id);
    }

    const isCreateForm = !license;
    const isUpdateForm = !isCreateForm;
    const hasPickedReseller = !!reseller;
    const hasPickedCustomer = !!org;

    return(
        <Dialog 
            className={`floor-form-dialog ${model.platformType === PlatformType.Wall ? " sportswall" : ""}`}
            onClose={onClose}
            icon={license ? 'trash' : undefined}
            onIconClick={showConfirmRemove}
            loading={loading}
            icons={[
                {
                    icon: "history", 
                    onClick: () => setShowLicenseHistory(true), 
                    hoverMsg: <Translate id='license_history_button' />, 
                    hidden: isCreateForm, 
                }
            ]}
        >
            {model &&
                <form onSubmit={onSubmit}>
                    <GeneralError error={generalErrors} />
                    <div className='flex'>
                        <div className='left'>
                            <SelectList
                                name='license_platform' 
                                onChange={setPlatformType}
                                defaultValue={model.platformType}
                                options={platformTypes.map(x => ({name: `license_${x.toLowerCase()}_name`, value: x, translate: true}))}
                                disabled={isUpdateForm}
                                errors={formErrors}
                                noUnsavedChanges={isCreateForm}
                            />
                            <OrganizationFormGroup 
                                name="license_org" 
                                value={org} 
                                onChange={setOrg} 
                                disabled={isUpdateForm} 
                                errors={formErrors} 
                                noUnsavedChanges={isCreateForm}
                                chooseHint="license_choose_existing_org"
                            />
                            {isCreateForm &&
                                <div className="form-group-related-text-btn" onClick={() => setCreateEndClient(true)} ><FontAwesomeIcon icon='plus' /> <Translate id="license_new_org" /></div>
                            }
                            <OrganizationFormGroup 
                                name="license_reseller" 
                                value={reseller} 
                                onChange={setReseller} 
                                disabled={!hasPickedCustomer} 
                                errors={formErrors} 
                                noUnsavedChanges={isCreateForm} 
                            />
                            {reseller && !reseller.economicCustomerNumber &&
                                <div className='admin-reseller-create'>
                                    <h3><Translate id={'license_create_org_missing_erp'} data={{name: reseller.systemName}} /></h3>
                                    <InputFormGroup
                                        type='text' 
                                        name='invoice_economic_id' 
                                        value={model?.resellerErpRef ?? ""} 
                                        onChange={e => setModel(x => x && ({...x, resellerErpRef: e.target.value}))}
                                        errors={formErrors}
                                        noUnsavedChanges={!license}
                                    />
                                </div>    
                            }
                            <div className='form-area'>
                                <h2><Translate id='license_finance_heading'/></h2>
                                <SelectList
                                    name="paymentType"
                                    labelName="license_payment_type"
                                    options={paymentTypes
                                        .filter(x => model.paymentType === "Unknown" ? x : x !== "Unknown") //filter out the unkown option, if it is not the current value
                                        .map(x => ({name: `license_paymenttype_${x}`, value: x, translate: true}))
                                    }
                                    defaultValue={model.paymentType}
                                    onChange={e => setModel(m => ({...m, paymentType: e.target.value as PaymentType}))}
                                    disabled={!hasPickedReseller}
                                    errors={formErrors}
                                    
                                />
                                <DatePickerFormGroup 
                                    name={'activatedDate'} 
                                    value={moment.unix(model?.activatedDate ?? 0)}
                                    onChangeUnix={e => setModel(m => m && ({
                                        ...m, 
                                        activatedDate: e.target.value,
                                        expireDate: isCreateForm ? dateHelper.toString(moment.unix(e.target.value).add(m.prepaidMonths, "months")) : m.expireDate
                                    }))}  
                                    errors={formErrors}
                                    hideUnsaved={isCreateForm}
                                    disabled={!hasPickedCustomer}
                                />
                                {model.paymentType !== "Free" &&
                                    <>
                                        {isUpdateForm &&
                                            <>
                                                <DatePickerFormGroup 
                                                    name={'reactivationDate'} 
                                                    label="license_reactivationDate"
                                                    value={dateHelper.toDate(model?.reactivationDate)}
                                                    onChangeUnix={e => setModel(m => m && ({
                                                        ...m, 
                                                        reactivationDate: dateHelper.epochToString(e.target.value)
                                                    }))}  
                                                    errors={formErrors}
                                                />
                                                <p><Translate id='license_reactivation_explain'/></p>
                                            </>
                                        }
                                        <InputFormGroup
                                            name='prepaidMonths'
                                            value={model?.prepaidMonths.toString() ?? ""}
                                            onChange={e => setModel(m => ({
                                                ...m,
                                                prepaidMonths: parseInt(e.target.value),
                                                expireDate: isCreateForm ? dateHelper.toString(moment.unix(m.activatedDate).add(parseInt(e.target.value), "months")) : m.expireDate
                                            }))}
                                            labelWidth100
                                            errors={formErrors} 
                                            type='number'
                                            noUnsavedChanges={isCreateForm}
                                            disabled={!hasPickedCustomer}
                                        />
                                        <DatePickerFormGroup 
                                            label="license_expireDate"
                                            name={'expireDate'} 
                                            value={dateHelper.toDate(model.expireDate)}
                                            onChangeUnix={e => setModel(m => ({...m, expireDate: dateHelper.epochToString(e.target.value)}))}  
                                            errors={formErrors}
                                            disabled={isCreateForm}
                                            hideUnsaved={isCreateForm}
                                            className={model.paymentType === "Terminated" ? "highlight" : undefined}
                                        />
                                        <PriceFormGroup
                                            name={'license_price'} 
                                            value={model.price} 
                                            onChange={e => setModel(m => m && ({...m, price: e.target.value}))} 
                                            errors={formErrors}
                                            disabled={!hasPickedReseller}
                                        />
                                        <DelayedInputFormGroup
                                            name='paymentFrequency'
                                            value={model?.paymentFrequency.toString() ?? "12"}
                                            onChange={e => {
                                                var paymentFrequency = parseInt(e.target.value);
                                                setModel(m => m && ({...m, paymentFrequency}));
                                                recalcPrice(paymentFrequency, model?.platformType, reseller);
                                            }}     
                                            type='number'
                                            errors={formErrors}
                                            delayMs={500}
                                            disabled={!hasPickedReseller}
                                        />     
                                        <p><Translate id='license_payment_freq_explain' /></p>
                                    </>
                                }
                                <InputFormGroup
                                    type="number"
                                    name="license_allowedNumberOfUnits"
                                    value={model.allowedNumberOfUnits.toString()}
                                    onChange={e => setModel(m => m && ({...m, allowedNumberOfUnits: Number.parseInt(e.target.value)}))}
                                    disabled={!hasPickedReseller}
                                /> 
                            </div>
                            <DatePickerFormGroup 
                                name={'installationDate'} 
                                value={moment.unix(model?.installationDate ?? 0)}
                                onChangeUnix={e => setModel(m => m && ({...m, installationDate: e.target.value}))}  
                                disabled={!hasPickedCustomer}
                            />
                            {isUpdateForm &&
                                <>
                                    <InputFormGroup 
                                        type='text' 
                                        onChange={() => {}} 
                                        name='org_id' 
                                        value={license.organizationId} 
                                        disabled 
                                        clickToCopy
                                        readOnly
                                    />
                                    <TextAreaFormGroup 
                                        clickToCopy 
                                        onChange={() => {}} 
                                        name='apiKey' 
                                        label={'api_key'} 
                                        value={license.apiKey} 
                                        readOnly 
                                    />
                                </>  
                            }
                        </div>
                        <div className='right'>
                            {isCreateForm &&
                                <>
                                    {templates && templates.length > 0 && 
                                        <SelectList
                                            name='floor_template_label' 
                                            defaultValue={model?.templateKey ?? ""} 
                                            options={
                                                [
                                                    ...templates.map(f => ({
                                                        name: `${f.name} [${f.systemName}]`,
                                                        value: f.apiKey
                                                    })),
                                                    {
                                                        name: "Custom",
                                                        value: templates.find(x => x.apiKey === model?.templateKey) ? "" : (model?.templateKey ?? "")
                                                    }
                                                ]
                                            }
                                            onChange={e => setModel(m => m && ({...m, templateKey: e.target.value}))}
                                            disabled={!hasPickedCustomer}
                                        />
                                    }
                                    <TextAreaFormGroup
                                        name='template'
                                        placeholder='floor_template_placeholder'
                                        label={'floor_template_label'}
                                        value={model?.templateKey ?? ""}
                                        onChange={e => setModel(m => m && ({...m, templateKey: e.target.value}))}
                                        errors={formErrors}
                                        noLabel={templates && templates.length > 0}
                                        disabled={!hasPickedCustomer}
                                    />
                                </>
                            }
                            <InputFormGroup 
                                name='name' 
                                type='text' 
                                value={model.name ?? ""} 
                                onChange={e => setModel(x => ({...x, name: e.target.value}))} 
                                errors={formErrors}
                                noUnsavedChanges={!license}
                                disabled={!hasPickedCustomer}
                            />
                            {model?.platformType === PlatformType.Wall && 
                                <CheckBox
                                    label='wall_is_rtl'
                                    name='isRtl'
                                    active={model.isRtl}
                                    onChange={e => setModel(m => ({ ...m, isRtl: e.target.value }))}
                                    hideUnsaved={!license}
                                    disabled={!hasPickedCustomer}
                                />
                            }
                            <TextAreaFormGroup 
                                name={'license_resellerNote'} 
                                value={model?.resellerNote ?? ""} 
                                onChange={e => setModel(m => ({...m, resellerNote: e.target.value}))} 
                                disabled={!hasPickedCustomer}
                            />
                            <TextAreaFormGroup 
                                name={'license_note'} 
                                value={model?.note ?? ""} 
                                onChange={e => setModel(m => ({...m, note: e.target.value}))} 
                                disabled={!hasPickedCustomer}
                            />
                           <RadioGroup
                                label="license_warning_state"
                                name="license_warning_state"
                                options={["Normal", "Always", "Never"].map(v => ({name: v, value: v}))}
                                value={model.licenseWarningState}
                                onChange={e => setModel(m => ({...m, licenseWarningState: e as LicenseWarningState}))}
                            />
                            {isUpdateForm && showLicenseHistory &&
                                <LicenseHistory licenseId={license.id} orgs={[reseller, org]} onClose={() => setShowLicenseHistory(false)}/>
                            }
                        </div>
                    </div>
                    <SubmitButton split />
                </form>
            } 
            {confirmRemoveDialog}
            {confirmPrice && reseller &&
                <ConfirmationForm 
                    text='fill_base_price'
                    textData={{reseller: reseller.systemName, price: `${confirmPrice.value} ${confirmPrice.currency}`}} 
                    confirmText='yes' 
                    cancelText='no' 
                    onCancel={() => setConfirmPrice(undefined)}
                    onConfirm={() => {
                        setConfirmPrice(undefined);
                        setModel(m => m && ({...m, price: confirmPrice}))
                    }}
                />
            }
            {createEndClient &&
                <OrganizationForm 
                    organization={null} 
                    orgCreated={setOrg} 
                    orgUpdated={() => {}} //Not relevant, can only use the form to create in this case
                    onClose={() => setCreateEndClient(false)} 
                />
            }
        </Dialog>
    )
}

export default AdminLicenseForm;