import { useCallback, useState } from "react";
import { GlobetrotterGame } from "../../../model/Game/Globetrotter/GlobetrotterGame";
import EditGameComponent from "../../EditGameComponent";
import { GameSettings } from "../../../api/BaseGameContext";
import { GlobetrotterContext } from "../../../api/Game/GlobetrotterContext";
import CreateMapForm from "./CreateMapForm";
import { Map } from "../../../model/Game/Globetrotter/Map";
import ItemContainer from "../../ModelPreview/ItemContainer";
import EditMapForm from "./EditMapForm";
import { GlobetrotterEditingContext } from "../../EditingContext";
import MapControl from "./MapControl";

const Globetrotter = () => {
    const [game, setGame] = useState<GlobetrotterGame>();
    const [showMapForm, setShowMapForm] = useState<boolean>();
    const [editMap, setEditMap] = useState<Map>();

    const newElementFunc = useCallback(() => setShowMapForm(true), []);

    const mapsWithoutLocations = game?.maps.filter(x => x.locations === undefined || x.locations.length === 0);
    const mapsWithMissingHints = game?.maps.filter(x => x.locations.some(l => l.hints === undefined || l.hints.length === 0));
 
    return (
        <GlobetrotterEditingContext.Provider value={{ game: game, setGame: setGame }}>
            <EditGameComponent<GlobetrotterGame, GameSettings>
                game={game}
                setGame={setGame}
                gameContext={GlobetrotterContext}
                tempDescription={'globetrotter_temp_text'}
                tempTitle={'globetrotter_create_title'}
                contentClass={"globetrotter"}
                publishRequirements={[
                    {
                        name: 'globetrotter_maps_required',
                        done: (game?.maps.length ?? 0) > 0,
                        action:  newElementFunc
                    },
                    {
                        name: 'globetrotter_locations_required',
                        done: mapsWithoutLocations?.length === 0,
                        action: () => mapsWithoutLocations && setEditMap(mapsWithoutLocations[0])
                    },
                    {
                        name: 'globetrotter_hints_required',
                        done: mapsWithMissingHints?.length === 0,
                        action: () => mapsWithMissingHints && setEditMap(mapsWithMissingHints[0])
                    }
                ]}
                publicRequirements={[]}
                newGameElementFunction={newElementFunc}
                settingsMapper={g => ({ skin: g.skin ?? "Default", document: g.document })}
            >
                <h1>Globetrotter</h1>

                {game &&
                    <ItemContainer
                        items={game.maps}
                        heading={<h2>Maps</h2>}
                        itemRender={item =>
                            <MapControl map={item} game={game} />                           
                        }

                    />}

                {showMapForm &&
                    <CreateMapForm
                        map={null}
                        gameId={game?.id}
                        onClose={() => setShowMapForm(false)}
                    />
                }
                {
                    editMap && editMap.id &&
                    <EditMapForm
                        map={editMap}
                        onClose={() => setEditMap(undefined)}
                    />
                }
            </EditGameComponent>
        </GlobetrotterEditingContext.Provider>
    )
}

export default Globetrotter;