import { useContext, useState } from "react"
import { TextType, TextTypeStyle } from "../model/Game/TextType";
import { getLocaleStorageItem, saveLocaleStorageItem } from "./LocalStorageService";
import { GlobalModalContext } from "../components/_MyFloor/MyFloorApp";

type UseStyleResult<T> = [Partial<T>, (t: T, showPop?: boolean) => void];

export const useStyle = <T extends unknown> (styleKey: string, defaultStyle: T, keysToRemove?: string[]): UseStyleResult<T> => {
    const {popMsg} = useContext(GlobalModalContext);
    const [style, setStyle] = useState(getLocaleStorageItem<T>(styleKey, defaultStyle, keysToRemove));
    
    const saveStyle = (s: T, showPop?: boolean) => {
        const saved = saveLocaleStorageItem(styleKey, s, keysToRemove);
        setStyle(saved);
        showPop && popMsg("pop_change", "pop_msg_style_saved");
    }

    return [style, saveStyle];
}

export const applyTextStyle = (o: TextType, s: Partial<TextTypeStyle>): TextType => {
    return{
        ...o, ...s
    };
}