import { useContext, useState } from "react";
import { Organization } from "../../../model/Organization";
import { FetchError } from "../../../services/FetchHelper";
import Translate from "../../Helper/Translate";
import UnsavedChangesIcon from "./UnsavedChangesIcon";
import FieldErrors from "../FieldErrors";
import OrganizationPicker from "../Organization/OrganizationPicker";
import { useTranslation } from "react-i18next";
import { MinimalOrganization } from "../../../model/Response/MinimalOrganization";
import { SecureContext } from "../../_MyFloor/MyFloorApp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InitialFilters } from "../../Organization/OrganizationList";

interface Props {
    name: string;
    value: MinimalOrganization|undefined;
    onChange: (o: Organization) => void;
    doneTyping?: () => void;
    errors?: FetchError;
    errorField?: string;
    label?: string;
    disabled?: boolean;
    disabledHint?: string;
    labelWidth100?: boolean;
    className?: string;
    noLabel?: boolean;
    noUnsavedChanges?: boolean;
    chooseHint?: string;
    filters?: InitialFilters;
}
const OrganizationFormGroup = (props: Props) => {
    const {name, value, filters, onChange: _onChange, errors: _errors, errorField, label: _label, disabled, disabledHint, labelWidth100, className, noLabel, noUnsavedChanges, chooseHint} = props;
    const {t} = useTranslation();
    const {isRtl} = useContext(SecureContext);
    const [changed, setChanged] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const onChange = (o: Organization) => {
        setShowPicker(false); 
        if(value?.id !== o.id){
            setChanged(true);
            _onChange(o)
        } 
    }; 
    const label = noLabel || <label className={labelWidth100 ? 'full-width' : ''} htmlFor={name}>{<Translate id={_label || name} />}</label>;

    const showUnsaved = !noUnsavedChanges && changed;

    const input =
        <>
            <input
                name={name}
                dir="auto"
                className={`form-control pointer${showUnsaved ? ' unsaved-changes' : ''}`}
                type={'text'}
                value={value?.systemName ?? t(chooseHint ?? "choose")}
                autoComplete={'off'}
                disabled={disabled}
                onClick={() => !disabled && setShowPicker(true)}
                readOnly
            />
            <UnsavedChangesIcon show={showUnsaved}/>
        </>;

    const errors = _errors && <FieldErrors errors={_errors} fieldname={errorField || name} />;

    return(
        <div className={`form-group icon-group ${className || ''}${disabled && disabledHint ? ' hover-trigger' : ''}`} >
            { label }
            <div className={`icon-input-wrap${isRtl ? ' rtl' : ''}`}><FontAwesomeIcon icon='building' className='input-icon' /> { input }</div>
            { errors }
            { disabled && disabledHint && <div className='hover-msg'><Translate id={disabledHint} /></div> }
            { showPicker && <OrganizationPicker filters={filters} onChoose={onChange} onClose={() => setShowPicker(false)} /> }
        </div>
    );
};


export default OrganizationFormGroup;