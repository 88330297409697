import React, { Dispatch, ReactNode, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react';

interface Props{
    setPage: (n: number) => void;
    currentPage: number;
    pageCount: number;
}

const Pagination = (props: Props) => {
    const {setPage, currentPage, pageCount} = props;

    useEffect(() => {
        if(currentPage > pageCount) setPage(pageCount-1);
    },[pageCount, currentPage, setPage]);

    const pages: number[] = [];
    for(let i = 0; i < pageCount; i++){
        pages.push(i);
    }

    const leftDisable = currentPage === 0;
    const rightDisable = currentPage >= (pageCount-1);

    return(
        <div className='pages'>
            <span className={leftDisable ? 'disabled' : ''} onClick={() => !leftDisable && setPage(currentPage-1)}>&lt;</span>
            {pages
                .filter(p => filterPages(p, currentPage, pages))//Avoid showing all pages, as this looks ridiculous when there is a lot of pages.
                .map(x => 
                    <span 
                        key={x} 
                        onClick={() => setPage(x)} 
                        className={x === currentPage ? 'active' : ''}
                    >
                        {x+1}
                    </span>
                )
            }
            <span className={rightDisable ? 'disabled' : ''} onClick={() => !rightDisable && setPage(currentPage+1)}>&gt;</span>
        </div>
    )
}

const maxVisiblePages = 6
const halfOfMax = 6 / 2;
const filterPages = (pageToRender: number, selectedPage: number, allPages: number[]) => {
    if(allPages.length <= maxVisiblePages) return true;//Filter out nothing in there is below 7 pages
    
    if(pageToRender === 0) return true; //Always show page 1
    if(pageToRender === allPages.length - 1) return true; //Always show the last page

    // pageToRender must
    // be among the first 6 pages(maxVisiblePages) or the selectedPage + half of maxVisiblPages
    // whichever is higher.
    //
    // AND
    //
    // pageToRender must
    // be among the last 6 pages(maxVisiblePages) or the selectedPage - half of maxVisiblePages
    // whichever is lower
    //
    // if there is 200pages. And the selected page is 100. we should show: 1  98  99  100 101 102 200
    // if there is 200pages. And the selected page is 2  . we should show: 1   2   3   4   5   6  200
    // if there is 200pages. And the selected page is 199. we should show: 1  195 196 197 198 199 200
    return(
        pageToRender < Math.max(selectedPage + halfOfMax, maxVisiblePages) &&
        pageToRender > Math.min(selectedPage - halfOfMax, allPages.length - maxVisiblePages + 1)
        
    )
}

export default Pagination;

export const usePagination = <T,> (items: T[], pageSize: number, container: RefObject<HTMLElement>): [ReactNode, T[], () => void] => {
    const [page, _setPage] = useState(0);

    const setPage = useCallback<Dispatch<SetStateAction<number>>>((i: number|((prevstate: number) => number)) => {
        _setPage(i);
        container.current?.scrollIntoView({block: "start", inline: "start", behavior: "smooth"});
    }, [_setPage, container]);

    if (page * pageSize - pageSize > items.length && page > 0) setPage(0);

    const min = page * pageSize;
    const max = (page + 1) * pageSize;
    const pageCount = Math.ceil(items.length/pageSize);

    return [pageCount < 2 ? undefined : <Pagination currentPage={page} setPage={setPage} pageCount={pageCount} />, items.slice(min, max), () => setPage(0)]
}