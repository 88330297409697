import moment, { Moment } from "moment";

const dateFormat = "YYYY-MM-DD";
const frontendFormat = "LL";

export const dateHelper = {
    toDate: (s: string|undefined) => s ? moment(s, dateFormat) : moment.unix(0),
    toString: (d: Moment) => d.unix() ? d.format(dateFormat) : "",
    epochToString: (unix: number) => unix ? dateHelper.toString(moment.unix(unix)) : "",
    max: (d: Moment, d2: Moment) => d > d2 ? d : d2,
    toFrontendString: (s: string|undefined) => dateHelper.toDate(s).format(frontendFormat),
    epochToFrontend: (unix: number) => unix ? moment.unix(unix).format(frontendFormat) : ""
};
