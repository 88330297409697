import { createBaseGameContext } from "../BaseGameContext";
import { GardenContext } from "./GardenContext";
import { SplatterSpaceContext } from "./SplatterSpaceContext";

export const AirhockeyContext = createBaseGameContext("Airhockey");
export const AwesomeForestContext = createBaseGameContext("AwesomeForest");
export const BrickOutContext = createBaseGameContext("BrickOut");
export const ButterfliesContext = createBaseGameContext("Butterflies");
export const ChessContext = createBaseGameContext("Chess");
export const DrumsContext = createBaseGameContext("Drums");
export const FishesContext = createBaseGameContext("Fishes");
export const SimonSaysContext = createBaseGameContext("SimonSays");
export const SoccerContext = createBaseGameContext("Soccer");
export const WallBallContext = createBaseGameContext("WallBall");
export const GalacticGolfContext = createBaseGameContext("GalacticGolf"); 
export const DragonDiceContext = createBaseGameContext("DragonDice"); 
export const PizzaPartyContext = createBaseGameContext("PizzaParty"); 

export const SimpleGameContexts = [
    AirhockeyContext,
    AwesomeForestContext,
    BrickOutContext,
    ButterfliesContext,
    ChessContext,
    DrumsContext,
    FishesContext,
    SimonSaysContext,
    SoccerContext,
    SplatterSpaceContext,
    WallBallContext, 
    GalacticGolfContext, 
    DragonDiceContext,
    GardenContext, 
    PizzaPartyContext
];