import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Loading } from "../Loading";
import Pagination from "./Pagination";
import { FilterType, columnsToFilters, useTableFilters } from "./TableFilters";
import { notUndefined } from "../../services/CustomFunctions";
import { ContextFunc, isFetchError } from "../../services/FetchHelper";
import { SearchResult } from "../../model/Response/SearchResult";
import { PageInfo } from "../../model/PageInfo";
import { GeneralError } from "../Error/GeneralError";
import SimpleTable, { TableColumn, TableSort } from "./SimpleTable";
import { sortTableItems } from "./TableSorting";

interface TableMutate<T extends {id: string}>{
    id: string;
    mutation: T|((l: T) => T);
    action: 'updated';
}
interface TableDelete{
    id: string;
    action: 'deleted';
}
interface TableCreate<T extends {id: string}>{
    item: T;
    action: 'created'
}
const isTableMutate = <T extends {id: string}> (item: TableCreate<T>|TableMutate<T>|TableDelete): item is TableMutate<T> => {
    return item.action === "updated";
}
const isTableDelete = <T extends {id: string}> (item: TableCreate<T>|TableMutate<T>|TableDelete): item is TableDelete => {
    return item.action === "deleted";
}
const isTableCreate = <T extends {id: string}> (item: TableCreate<T>|TableMutate<T>|TableDelete): item is TableCreate<T> => {
    return item.action === "created";
}

export type TableLocalChange<T extends {id: string}> = TableCreate<T>|TableMutate<T>|TableDelete;

interface Props<T extends {id: string}, T2 extends PageInfo>{
    columns: (undefined|TableColumn<T>)[];
    contextMethod: ContextFunc<SearchResult<T>, [T2]>;
    makeSearchModel: (filters: FilterType<T>[], sort: TableSort, size: number, offset: number) => T2;

    rowClass?: (x: T) => string|undefined; 
    defaultSort?: {value: string, asc: boolean};
    pageSize: number;
    heading?: ReactNode;
    subheading?: ReactNode; 
    className?: string;
    onRowClick?: (item: T) => void;
    localChanges?: TableLocalChange<T>[];
    onSortChange?: (newValue: {value: string, asc: boolean}) => void;
}

const SearchResultTable = <T extends {id: string}, T2 extends PageInfo>(props: Props<T,T2>) => {
    const {contextMethod, makeSearchModel, heading, subheading, columns: _columns, className, pageSize, defaultSort, onRowClick, rowClass, localChanges, onSortChange} = props;
    const tableRef = useRef<HTMLTableElement>(null);

    const [fetchGet, loading, error] = contextMethod();
    const loadPage = useCallback(
        (i: number, f: FilterType<T>[], s: {value: string|undefined; asc: boolean}) => {
            fetchGet(makeSearchModel(f, s, pageSize, i * pageSize)).then(x => !isFetchError(x) && setSearchResult(x));
        },
        [fetchGet, pageSize, makeSearchModel]
    );
    
    const columns = _columns.filter(notUndefined);
    const [searchResult, setSearchResult] = useState<SearchResult<T>>({items: [], offset: 0, total: 0});
    const [searchRequest, setSearchRequest] = useState<{page: number, sort: TableSort, filters: FilterType<T>[]}>({page: 0, filters: columnsToFilters(columns), sort: defaultSort ?? {value: columns[0]?.id, asc: true}})
    //When there is less items visible than pagesize, we will not do a request to the backend to resort the items, but just update the sorting in the frontend.
    const [frontendSort, setFrontendSort] =  useState<TableSort>(searchRequest.sort);
    const sort = searchResult.total > pageSize ? searchRequest.sort : frontendSort;
    const setSort = (header: string) => {
        const newSort = {...sort, value: header, asc: sort.value === header ? !sort.asc : true };
        setFrontendSort(newSort);
        if(onSortChange) onSortChange(newSort);
        if(searchResult.total > pageSize){
            setSearchRequest(o => ({...o, sort: newSort}));
        }
        else{
            setSearchResult(sr => ({...sr, items: sortTableItems(sr.items, columns, newSort)}));
        }   
    }

    useEffect(() => {
        loadPage(searchRequest.page, searchRequest.filters, searchRequest.sort);
    }, [loadPage, searchRequest]);

    const [filterNode] = useTableFilters<T>(searchResult.items[0], columns, f => setSearchRequest(o => ({...o, sort: frontendSort, filters: f, page: 0})));

    const pageCount = Math.ceil(searchResult.total / pageSize);

    const newItems = localChanges?.filter(isTableCreate).map(x => x.item) ?? [];
    const localUpdates = localChanges?.filter(isTableMutate) ?? [];
    const localDeletes = localChanges?.filter(isTableDelete) ?? [];
    const applyMutators = (x: T, mutators: (T|((i: T) => T))[]) => {
        for(const mutator of mutators){
            if(typeof(mutator) === "function") x = mutator(x);
            else x = mutator;
            
        }
        return x;
    }
    const newItemsMutated = newItems
        .filter(x =>!localDeletes.find(ld => ld.id === x.id))
        .filter(x => !searchResult?.items.find(i => i.id === x.id))
        .map(x => applyMutators(x, (localUpdates.filter(lc => lc.id === x.id) ?? []).map(x => x.mutation)));
    const items = searchResult?.items
        .filter(x => !localDeletes.find(ld => ld.id === x.id))
        .map(x => applyMutators(x, (localUpdates.filter(lc => lc.id === x.id) ?? []).map(x => x.mutation)));

    return(
        <div className={`item-container-table`}>
            <Loading visible={loading} />
            <GeneralError error={error} />
            <div>
                {heading && <div className='table-heading'>{heading}</div>}
                {subheading && <div className='table-sub-heading'>{subheading}</div>}
            </div>
            <div className={className}>
                <SimpleTable 
                    items={[...newItemsMutated, ...items]}
                    columns={columns}
                    tableRef={tableRef}
                    filterNode={filterNode}
                    sort={sort}
                    setSort={setSort}
                    onRowClick={onRowClick}
                    rowClass={rowClass}
                />
            </div>
            {pageCount > 1 && 
                <Pagination 
                    currentPage={searchRequest.page} 
                    setPage={i => {
                        tableRef.current?.scrollIntoView({block: "start", inline: "start", behavior: "smooth"});
                        setSearchRequest(o => ({...o, page: i}))
                    }} 
                    pageCount={pageCount} 
                />
            }
        </div>
    )
};

export default SearchResultTable;