import { ReactNode, useMemo, useRef, useState } from "react";
import { Loading } from "../Loading";
import { usePagination } from "./Pagination";
import { applyFilters, useTableFilters } from "./TableFilters";
import { notUndefined } from "../../services/CustomFunctions";
import SimpleTable, { TableColumn } from "./SimpleTable";
import { sortTableItems } from "./TableSorting";
import { Button } from "../forms/FormGroup";
import { useTranslation } from "react-i18next";
import { getCsvFileString } from "../../services/CsvHelper";

interface Props<T extends {id: string}>{
    loading?: boolean;
    items: T[];
    columns: (undefined|TableColumn<T>)[];
    rowClass?: (x: T) => string|undefined; 
    defaultSort?: {value: string, asc: boolean};
    pageSize: number;
    heading?: ReactNode;
    subheading?: ReactNode; 
    className?: string;
    onRowClick?: (item: T) => void;
    csvDownload?: boolean;
    nothingNode?: ReactNode;
    onSortChange?: (newSort:  {value: string, asc: boolean}) => void;
}

const ItemContainerTable = <T extends {id: string}>(props: Props<T>) => {
    const {loading, items, heading, subheading, columns: _columns, className, pageSize, defaultSort, onRowClick, rowClass, csvDownload, nothingNode, onSortChange} = props;
    const {t} = useTranslation();
    const tableRef = useRef<HTMLTableElement>(null);
    const columns = _columns.filter(notUndefined);
    const [sort, _setSort] = useState<{value: string|undefined, asc: boolean}>(defaultSort ?? {value: columns[0]?.id, asc: true});

    const setSort = (columnId: string) => {
        const newSort = {...sort, value: columnId, asc: sort.value === columnId ? !sort.asc : true };
        _setSort(newSort);
        if(onSortChange) onSortChange(newSort); 
    }

    const [_items, setItems] = useState<T[]>(items);
    
    const [filteredItems, setFilteredItems] = useState<T[]>(items);
    const [filterNode, filters] = useTableFilters(items[0], columns, x => {setFilteredItems(applyFilters(items, x)); resetPage()});
    if(items !== _items){
        setItems(items);
        setFilteredItems(applyFilters(items, filters));
    } 

    const sortedItems = useMemo(() => sortTableItems(filteredItems, columns, sort), [sort, filteredItems, columns]);

    const [pagination, paginatedItems, resetPage] = usePagination(sortedItems, pageSize, tableRef);

    const downloadClick = () => {
        const csv = getCsvFileString(items, columns, t);
        const element = document.createElement("a");
        const file = new Blob([csv], {type: 'text/csv'});
        element.href = URL.createObjectURL(file);
        element.download = "table_export.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return(
        <div className={`item-container-table`}>
            <Loading visible={loading} />
            <div>
                {heading && <div className='table-heading'>{heading}</div>}
                {(subheading || csvDownload) && 
                    <div className='table-sub-heading'>
                        {subheading}
                        {csvDownload && <Button onClick={downloadClick} icon={'file-csv'} name="download" />}
                    </div>
                }
            </div>
            <div className={className}>
                <SimpleTable
                    items={paginatedItems}
                    columns={columns}
                    tableRef={tableRef}
                    filterNode={filterNode}
                    sort={sort}
                    setSort={setSort}
                    onRowClick={onRowClick}
                    rowClass={rowClass}
                    nothingNode={nothingNode}
                />
            </div>
            {pagination}
        </div>
    )
};

export default ItemContainerTable;