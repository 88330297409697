import { useCallback } from "react";
import { ContextFunc, useFetchGet } from "../services/FetchHelper";
import { Wall } from "../model/Wall";

export interface IWallContext{
    useOrgWalls: ContextFunc<Wall[], [string]>;
    useWall: ContextFunc<Wall, [string]>; 
}

const baseUrl = "api/wall";

export const WallContext: IWallContext = {
    useOrgWalls: () => {
        const [rawInvoke, loading, error] = useFetchGet<Wall[]>();
        const invoke = useCallback((orgId: string) => rawInvoke(`${baseUrl}/byorg/${orgId}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useWall: () => {
        const [rawInvoke, loading, error] = useFetchGet<Wall>(); 
        const invoke = useCallback((wallId: string) => rawInvoke(`${baseUrl}/${wallId}`), [rawInvoke]); 
        return [invoke, loading, error];
    }
}