import { useState } from "react";
import { UnitContext } from "../../api/UnitContext";
import { DuplicateLicenseInfo } from "../../model/Response/DuplicatedLicenseInfo";
import { Button } from "../forms/FormGroup";
import { GeneralError } from "../Error/GeneralError";
import ConfirmationForm from "../forms/games/ConfirmationForm";
import ErrorWithLabel from "../forms/ErrorWithLabel";

interface Props{
    info: DuplicateLicenseInfo;
}

const DuplicateLicenseMailButton = (props: Props) => {
    const {info} = props;
    const [sendMail, loadingMail, errorMail] = UnitContext.useDuplicatesMail();
    const [showSendEmail, setShowSendEmail] = useState(false);

    return(
        <>
            <Button disabled={loadingMail} icon={'envelope'} name="license_duplication_mail_reseller" onClick={() => setShowSendEmail(true)} />
            {errorMail &&
                <>
                    <GeneralError error={errorMail} />
                    <ErrorWithLabel errors={errorMail} label={"license_reference"} errorKey={"LicenseRef"}  />
                    <ErrorWithLabel errors={errorMail} label={"license_contact_email"} errorKey={"LicenseContactEmail"}  />
                    <ErrorWithLabel errors={errorMail} label={"license_duplication_2unit_count"} errorKey={"Units"}  />
                </> 
            }
            {showSendEmail &&
                <ConfirmationForm 
                    text={"license_duplication_mail_reseller_send"} 
                    onConfirm={() => sendMail(info).then(() => setShowSendEmail(false))} 
                    onCancel={() => setShowSendEmail(false)}       
                    loading={loadingMail}             
                />
            }
        </>
    )
}

export default DuplicateLicenseMailButton;