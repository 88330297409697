import { FormEvent, useContext, useState } from "react";
import { GameSettings } from "../../../api/BaseGameContext";
import { EscapeRoomGame } from "../../../model/Game/EscapeRoom/EscapeRoomGame";
import { SettingsProps } from "../../EditGameComponent";
import { SecureContext } from "../../_MyFloor/MyFloorApp";
import { EscapeRoomContext } from "../../../api/Game/EscapeRoomContext";
import { isFetchError } from "../../../services/FetchHelper";
import Dialog from "../../Dialog";
import Translate from "../../Helper/Translate";
import { GeneralError } from "../../Error/GeneralError";
import { accountHasAnyPermission } from "../../../model/AccountType";
import { DocumentFormGroup } from "../../forms/FormGroups/DocumentFormGroup";
import { SubmitButton } from "../../forms/FormGroup";


const EscapeRoomSettings = (props: SettingsProps<EscapeRoomGame, GameSettings>) => {
    const { gameId, settings: _settings, onClose, gameUpdated } = props;
    const {me} = useContext(SecureContext);
    const [settings, setSettings] = useState(_settings);
    const [saveSettings, loading, error] = EscapeRoomContext.useUpdateSettings();


    const submit = async (e: FormEvent) => {
        e.preventDefault();
        const result = await saveSettings(gameId, settings);
        if(!isFetchError(result)) gameUpdated(result);
    }

    return(
        <Dialog className='settings' onClose={onClose} loading={loading} >
            <h2><Translate id='settings'/></h2>
            <form onSubmit={submit}>
                <GeneralError error={error} /> 
                {accountHasAnyPermission(me, 'Editor','Admin') &&
                    <DocumentFormGroup value={settings.document} name='document_upload' onChange={document => setSettings(x => ({...x, document}))} />
                }
                <SubmitButton split />
            </form>
        </Dialog>
    )
}
export default EscapeRoomSettings; 