import { useCallback } from "react";
import { ContextFunc, useFetchDelete, useFetchGet, useFetchPost } from "../services/FetchHelper";
import { CreateRemoteAppConfig, RemoteAppConfig } from "../model/Unit/RemoteAppConfig";

interface IRemoteConfigContext{
    useAll: ContextFunc<RemoteAppConfig[], []>;
    useGet: ContextFunc<RemoteAppConfig, [string]>;
    useRemove: ContextFunc<void, [string]>;
    useCreate: ContextFunc<RemoteAppConfig, [string, CreateRemoteAppConfig]>;
    useBlock: ContextFunc<void, [string]>;
}

export const RemoteConfigContext: IRemoteConfigContext = {
    useAll: () => {
        const [rawInvoke, loading, error] = useFetchGet<RemoteAppConfig[]>();
        const invoke = useCallback(() => rawInvoke('api/remoteappconfig'), [rawInvoke]);
        return [invoke, loading, error];
    },
    useGet: () => {
        const [rawInvoke, loading, error] = useFetchGet<RemoteAppConfig>();
        const invoke = useCallback((id: string) => rawInvoke(`api/remoteappconfig/${encodeURIComponent(id)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useRemove: () => {
        const [rawInvoke, loading, error] = useFetchDelete<void>();
        const invoke = useCallback((id: string) => rawInvoke(`api/remoteappconfig/${encodeURIComponent(id)}`), [rawInvoke]);
        return [invoke, loading, error];
    },
    useCreate: () => {
        const [rawInvoke, loading, error] = useFetchPost<RemoteAppConfig>();
        const invoke = useCallback((id: string, model: CreateRemoteAppConfig) => rawInvoke(`api/remoteappconfig/${encodeURIComponent(id)}`, model), [rawInvoke]);
        return [invoke, loading, error];
    },
    useBlock: () => {
        const [rawInvoke, loading, error] = useFetchPost<void>();
        const invoke = useCallback((id: string) => rawInvoke(`api/remoteappconfig/${encodeURIComponent(id)}/block`), [rawInvoke]);
        return [invoke, loading, error];
    }
}