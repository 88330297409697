import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BulkPrice, Currency } from '../../../model/Price';
import Translate from '../../Helper/Translate';

interface Props{
    value: BulkPrice[];
    name: string;
    label?: string;
    onChange?: (prices: BulkPrice[]) => void;
    disabled?: boolean;
    renderKey?: string;
}

const defaultPrice = {value: 0, currency: Currency.DKK, bulkAmount: 0};

const VolumePriceFormGroup = (props: Props) => {
    const {name, label, disabled, onChange} = props;
    const [value, setValue] = useState(props.value.length <= 0 ? [defaultPrice] : props.value);
    const [renderKey, setRenderKey] = useState(props.renderKey);
    const [t, setT] = useState<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if(renderKey !== props.renderKey){
            setValue(props.value || [defaultPrice]);
            setRenderKey(props.renderKey);
        }
        
    }, [props.renderKey, renderKey, props.value]);

    if(!onChange) return (<VolumePriceFormGroupReadonly value={value} name={label||name} />);

    const inputTempPrices = (tempPrice: BulkPrice[], prices: BulkPrice[]) => {
        setValue(tempPrice);
        fireDelayedChange(prices);
    }

    const inputPrices = (prices: BulkPrice[]) => {
        setValue(prices);
        onChange(prices);
    }

    const fireDelayedChange = (prices: BulkPrice[]) => {
        if (t) clearTimeout(t);
        const ti = setTimeout(() => inputPrices(prices), 500);
        setT(ti);
    };
    
    return(
        <div className='form-group currency'>
            <label className={'full-width'} htmlFor={name}><Translate id={label || name} /></label>
            <table className='volume-price-table'>
                <thead>
                    <tr>
                        <th colSpan={2}><Translate id='months' /></th>
                        <th colSpan={2}><Translate id='price_pr_month'/></th>
                        {!disabled && <th><FontAwesomeIcon className='add-icon' icon='plus' onClick={() => inputPrices([...value, {value: value[value.length -1].value, currency: Currency.DKK, bulkAmount: value[value.length -1].bulkAmount + 12}])} /></th>}
                    </tr>
                </thead>
                <tbody>
                {
                    value.map((price, i) =>
                        <tr key={i}>
                            <td className='month'>
                                {price.bulkAmount === 0 
                                ? 1
                                :
                                    <input
                                        type='number'
                                        value={price.bulkAmount}
                                        name={name}
                                        className={`form-control`}
                                        disabled={disabled}
                                        onChange={event => {
                                            if(event.target.value === ''){
                                                inputPrices(value.map((p,j) => j === i ? ({...p, bulkAmount: 0}) : p));
                                            } 
                                            else if(!isNaN(parseFloat(event.target.value))){
                                                const newValue = parseFloat(event.target.value);
                                                const minValue = value[i-1].bulkAmount;
                                                const maxValue = i === value.length -1 ? Number.MAX_VALUE : value[i+1].bulkAmount;
                                                inputTempPrices(
                                                    value.map((p,j) => j === i ? ({...p, bulkAmount: newValue}) : p),
                                                    value.map((p,j) => j === i ? ({...p, bulkAmount: Math.min(Math.max(minValue +1, newValue), maxValue - 1)}) : p)
                                                );
                                            } 
                                        }}
                                    />
                                }
                                
                            </td>
                            <td className='month'>{i === value.length-1 ? '∞' : value[i+1].bulkAmount - 1}</td>
                            <td>
                                <input
                                    type='number'
                                    value={price.value}
                                    name={name}
                                    className={`form-control price`}
                                    disabled={disabled}
                                    onChange={event => {
                                        if(event.target.value === ''){
                                            inputPrices(value.map((p,j) => j === i ? ({...p, value: 0}) : p));
                                        } 
                                        else if(!isNaN(parseFloat(event.target.value))){
                                            inputPrices(value.map((p,j) => j === i ? ({...p, value: parseFloat(event.target.value)}) : p));
                                        } 
                                    }}
                                />
                            </td>
                            <td>
                            <select
                                name={name}
                                className={`form-control currency`}
                                onChange={event => inputPrices(value.map(x => ({...x, currency: event.target.value as Currency})))}
                                value={value.length <= 0 ? defaultPrice.currency : price.currency }
                                disabled={disabled}
                            >
                                {
                                    Object.values(Currency).map(c => <option key={c} value={c}>{c}</option>)
                                }
                            </select>
                            </td>
                            {!disabled && price.bulkAmount !== 0 &&
                                <td>
                                    <FontAwesomeIcon className='trash' icon='trash' onClick={() => inputPrices(value.filter((_,j) => j !== i))} />
                                </td>
                            }
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    )
};

export default VolumePriceFormGroup;


export const VolumePriceFormGroupReadonly = (props: {value: BulkPrice[]; name?: string;}) => {
    const {value, name} = props;

    return(
        <div className='bulk-price'>
            {name && <h2><Translate id={name} /></h2>}
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}><Translate id='months' /></th>
                        <th colSpan={2}><Translate id='price_pr_month'/></th>
                    </tr>
                </thead>
                <tbody>
                    {value.map((price, i) =>
                        <tr key={i}>
                            <td className='month'>{price.bulkAmount || 1}</td>
                            <td className='month'>{i === value.length-1 ? '∞' : value[i+1].bulkAmount - 1}</td>
                            <td>{price.value}</td>
                            <td>{value.length <= 0 ? defaultPrice.currency : price.currency}</td>
                        </tr>
                    )}
                </tbody>
        </table>
    </div>
    )
}