import React, { FormEvent, useContext, useState } from "react";
import Dialog from "../../Dialog";
import { CheckBox, ImageFormButton, SubmitButton } from "../../forms/FormGroup";
import { GlobetrotterContext } from "../../../api/Game/GlobetrotterContext";
import { useStyle } from "../../../services/UseStyle";
import MapPreview from "./MapPreview";
import InputFormGroup from "../../forms/FormGroups/InputFormGroup";
import Translate from "../../Helper/Translate";
import { Map } from "../../../model/Game/Globetrotter/Map";
import { GlobetrotterEditingContext } from "../../EditingContext";
import { isFetchError } from "../../../services/FetchHelper";
import { useConfirmDeleteAsync } from "../../forms/games/ConfirmationForm";
import { GeneralError } from "../../Error/GeneralError";

interface Props {
    map: Map | null;
    onClose: () => void;
    gameId?: string;
}

const styleKey = "globetrotter_map_style";
const initialMap: Map = { scaleRatio: 0, snapToLocation: false, locations: [], id: "", mapName: "" };

const CreateMapForm = (props: Props) => {
    const { setGame } = useContext(GlobetrotterEditingContext);

    const { onClose, map, gameId } = props;
    const [style, saveStyle] = useStyle<Map>(styleKey, initialMap, ["id", "mapImage", "mapName", "scaleRatio"]);
    const [model, setModel] = useState<Map>(map ?? { ...initialMap, ...style })

    const [createMap, loadingCreate, errorCreate] = GlobetrotterContext.useCreateMap();
    const [updateMap, loadingUpdate, errorUpdate] = GlobetrotterContext.useUpdateMap();
    const [deleteMap, loadingDelete, errorDelete] = GlobetrotterContext.useDeleteMap();

    const [confirmDelete, showConfirmDelete] = useConfirmDeleteAsync(
        map && map?.id && gameId
            ? async () => deleteMap(gameId, map.id)
            : undefined,
        g => { setGame(g); onClose(); }
    );

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const cm = { ...model, scaleRatio: isNaN(model.scaleRatio) ? 0 : model.scaleRatio, reuseMapImage: false };
        const result = gameId && map !== null && map.id ?
            await updateMap(gameId, map.id, cm) :
            await createMap(gameId, cm);

        if (!isFetchError(result)) {
            saveStyle(model);
            setGame(result);
        }

        onClose();
    }
 
    return (
        <Dialog
            loading={loadingCreate || loadingUpdate || loadingDelete}
            onClose={onClose}
            icon={map?.id ? 'trash' : undefined}
            onIconClick={showConfirmDelete}
        >
            {confirmDelete}
            <h1><Translate id={map ? "globetrotter_edit_map" : "globetrotter_create_map"}/></h1>
            <form className="globetrotter-map-form" onSubmit={onSubmit}>
                <div className="flex">
                    <div>
                        <InputFormGroup
                            name='mapName'
                            labelWidth100
                            label="globetrotter_map_name"
                            type='text'
                            value={model.mapName ?? ""}
                            placeholder='globetrotter_map_name'
                            onChange={(e) => setModel(x => ({ ...x, mapName: e.target.value }))}
                            tabIndex={1}
                        />
                        <label><Translate id="globetrotter_map_image" /></label>
                        <ImageFormButton
                            name={'image'}
                            imageSubmit={mapImage => setModel(x => ({ ...x, mapImage }))}
                            previewWidth={800}
                            previewHeight={800}
                            value={model.mapImage}
                            fixedAspect={true}
                            errors={errorCreate || errorUpdate}
                            errorKey={"Image"}
                        />
                    </div>
                    <div>
                        <InputFormGroup 
                            name='mapName'
                            labelWidth100
                            label="globetrotter_scale_ratio"
                            type='number'
                            value={model.scaleRatio.toString() ?? ""}
                            placeholder='globetrotter_scale_ratio'
                            onChange={(e) => setModel(x => ({ ...x, scaleRatio: parseFloat(e.target.value) }))}
                            tabIndex={1}
                        />
                        <CheckBox name='globetrotter_snap_to_location' active={model.snapToLocation} onChange={e => setModel(x => ({ ...x, snapToLocation: e.target.value }))} />
                    </div>
                </div>

                {model?.mapImage &&
                    <>
                        <label><Translate id="globetrotter_map_preview" /></label>
                        <MapPreview map={model}></MapPreview>
                    </>
                }
                <GeneralError error={errorCreate || errorDelete ||errorUpdate}/>
                <SubmitButton split disabled={!model.mapImage || !model.mapName} />
            </form>
        </Dialog>
    )
}

export default CreateMapForm;