import { FormEvent, useContext, useEffect, useState } from "react"
import { Button, SubmitButton } from "../forms/FormGroup"
import { SecureContext } from "../_MyFloor/MyFloorApp"
import { accountIsAdmin } from "../../model/AccountType";
import { DuplicateLicenseInfo } from "../../model/Response/DuplicatedLicenseInfo";
import Dialog from "../Dialog";
import Translate from "../Helper/Translate";
import InputFormGroup from "../forms/FormGroups/InputFormGroup";
import { LicenseContext } from "../../api/LicenseContext";
import { License } from "../../model/License";
import { FetchError, isFetchError } from "../../services/FetchHelper";
import { Price } from "../../model/Price";
import PriceFormGroup from "../forms/FormGroups/PriceFormGroup";
import { Floor } from "../../model/Floor";
import { Wall } from "../../model/Wall";
import { PlatformType } from "../../model/PlatformType";
import { licenseToModel } from "../License/AdminLicenseForms/AdminLicenseForm";
import { GeneralError } from "../Error/GeneralError";
import { useTranslation } from "react-i18next";

interface Props{
    info: DuplicateLicenseInfo;
    onLicenseUpdated: (newAllowed: number) => void;
}

const DuplicatedLicenseExtendAllowedButton = (props: Props) => {
    const {info, onLicenseUpdated} = props;
    const unit = info.units[0];
    const {t} = useTranslation();
    const [getLicense, loadingLicense, errorLicense] = LicenseContext.useLicense();
    const [updateFloor, loadingUpdateFloor, errorUpdateFloor] = LicenseContext.useUpdateFloorAdmin();
    const [updateWall, loadingUpdateWall, errorUpdateWall] = LicenseContext.useUpdateWallAdmin();
    const loading = loadingLicense||loadingUpdateFloor||loadingUpdateWall;
    const error = errorLicense||errorUpdateFloor||errorUpdateWall;
    const {me} = useContext(SecureContext);
    const isAdmin = accountIsAdmin(me);
    const [showForm, setShowForm] = useState(false);
    const [model, setModel] = useState<{allowedNumberOfUnits: number, price?: Price}>({allowedNumberOfUnits: 0});
    const [license, setLicense] = useState<Floor|Wall>();

    useEffect(() => {
        if(showForm && unit.licenseId){
            getLicense(unit.platformType, unit.licenseId).then(x => {
                if(!isFetchError(x)){
                    setModel({
                        allowedNumberOfUnits: x.allowedNumberOfUnits, 
                        price: x.financialData?.price
                    });
                    setLicense(x);
                } 
            });
        }
    }, [showForm, unit.licenseId, unit.platformType, getLicense]);

    if(!isAdmin) return null;
    
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if(!license) return;
        let result: FetchError|License|undefined = undefined;
        const rawRequestModel = licenseToModel(license);
        const requestModel = {
            ...rawRequestModel,
            allowedNumberOfUnits: model.allowedNumberOfUnits,
            price: model.price ? model.price : rawRequestModel.price
        };
        switch(license.platformType){
            case PlatformType.Floor:
                result = await updateFloor(license.id, requestModel);
                break;
            case PlatformType.Wall:
                result = await updateWall(license.id, requestModel);
                break;
            default: return;
        }
        if(result && !isFetchError(result)){
            onLicenseUpdated(model.allowedNumberOfUnits);
            setShowForm(false);
        }
    }

    return(
        <>
            <Button icon={"angle-double-up"} name="license_duplicate_extend_allowed" onClick={() => setShowForm(true)}/>
            {showForm &&
                <Dialog onClose={() => setShowForm(false)} loading={loading}>
                    <h2><Translate id='license_edit' data={{org: unit.orgName}} /></h2>
                    <GeneralError error={error} />
                    <form onSubmit={onSubmit} >
                        <InputFormGroup
                            autoFocus
                            type="number"
                            name="license_allowedNumberOfUnits"
                            value={model.allowedNumberOfUnits.toString()}
                            onChange={e => setModel(m => ({...m, allowedNumberOfUnits: Number.parseInt(e.target.value)}))}
                            errors={error}
                            errorField="allowedNumberOfUnits"
                            noUnsavedChanges
                        />
                        <InputFormGroup
                            name="paymentType"
                            label="license_payment_type"
                            value={t(`license_paymenttype_${license?.financialData?.paymentType}`)}
                            onChange={() => {}}
                            disabled
                            noUnsavedChanges
                        />
                        {model.price && license?.financialData?.paymentType !== "Free" &&
                            <PriceFormGroup
                                name={'license_price'} 
                                value={model.price} 
                                onChange={e => setModel(m => ({...m, price: e.target.value}))} 
                                errors={error}
                                errorKey="price"
                            />
                        }
                        <SubmitButton split />
                    </form>
                </Dialog>
            }
        </>
    )
}

export default DuplicatedLicenseExtendAllowedButton;