import { useContext, useEffect, useState } from "react";
import { UnitContext } from "../../api/UnitContext";
import { isFetchError } from "../../services/FetchHelper";
import InfoTable from "../Organization/InfoTable";
import ItemContainerTable from "../Collections/ItemContainerTable";
import moment from "moment";
import { DuplicateLicenseInfo, UnitLicenseDto } from "../../model/Response/DuplicatedLicenseInfo";
import Flag from "react-flagpack";
import { Loading } from "../Loading";
import { GeneralError } from "../Error/GeneralError";
import { Button } from "../forms/FormGroup";
import Translate from "../Helper/Translate";
import { distinct, toClassName, updateArrayItemsById } from "../../services/CustomFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RemoteConfigContext } from "../../api/RemoteConfigContext";
import Dialog from "../Dialog";
import RemoteConfigForm from "../License/Storage/RemoteConfigForm";
import { SecureContext } from "../_MyFloor/MyFloorApp";
import { accountIsAdmin } from "../../model/AccountType";
import DuplicateLicenseMailButton from "./DuplicateLicenseMailButton";
import DuplicatedLicenseExtendAllowedButton from "./DuplicateLicenseExtendAllowedButton";

const DuplicateLicenses = () => {
    const {me} = useContext(SecureContext);
    const isAdmin = accountIsAdmin(me);
    const [get, loading, error] = UnitContext.useDuplicates();
    const [blockUnit, loadingBlockUnit, errorBlockUnit] = RemoteConfigContext.useBlock();
    const [duplicated, setDuplicated] = useState<DuplicateLicenseInfo[]>([]);

    const [maximizedOrgs, setMaximizedOrgs] = useState<string[]>([]);
    const [showSetLicense, setShowSetLicense] = useState<UnitLicenseDto>();

    useEffect(() => {
        get().then(x => !isFetchError(x) && setDuplicated(x));
    }, [get])

    const updateUnit = (updatedUnit: UnitLicenseDto) => {
        setDuplicated(old => old.map(di => di.licenseRef !== updatedUnit.licenseRef 
            ? di 
            : ({...di, units: updateArrayItemsById(di.units, updatedUnit)})
        ));
    }

    const blockLicenseOnUnit = async (unit: UnitLicenseDto) => {
        const result = await blockUnit(unit.id);
        if(!isFetchError(result)){
            updateUnit({...unit, isLicenseBlocked: true});
        }
    }
    
    const invoicedOrgIds = distinct(duplicated.map(x => x.invoicedOrg?.id));

    return(
        <div className="layout-content duplicated-licenses">
            <Loading visible={loading||loadingBlockUnit} />
            <GeneralError error={error||errorBlockUnit} />
            <Button icon={"refresh"} name="refresh" onClick={() => get().then(x => !isFetchError(x) && setDuplicated(x))} />
            {duplicated.length === 0 && !loading &&
                <h1><Translate id='license_duplication_nothing'/></h1>
            }
            {invoicedOrgIds.map(invoicedId => {
                const duplicationInfos = duplicated.filter(x => x.invoicedOrg?.id === invoicedId);
                const reseller = duplicationInfos[0].invoicedOrg;
                const maximized = maximizedOrgs.includes(invoicedId);
                return(
                    <div key={invoicedId}>
                        <h2 className="org-title" onClick={() => setMaximizedOrgs(old => maximized ? old.filter(x => x !== invoicedId) : [invoicedId, ...old])}>
                            <Flag size='s' code={reseller.country} hasDropShadow /> {reseller.systemName} (<Translate id='license_duplication_license_count' data={{count: duplicationInfos.length}} />)
                            <FontAwesomeIcon icon={maximized ? 'minus' : 'plus'} />
                        </h2>
                        <div className={toClassName('org-container', maximized ? "max" : 'min')}>
                            {duplicationInfos.map(info => {
                                const unit =  info.units[0];
                                const reseller = info.invoicedOrg;
                                const numberOfUNits = info.units.length;
                                return(
                                    <div key={info.licenseRef} className='license'>
                                        <h2>{unit.licenseRef} - {unit.orgName} - {unit.licenseName}</h2>
                                        <div className="flex gap">
                                            <InfoTable
                                                info={[
                                                    {name: "reseller", value: <span><Flag size='s' code={reseller.country} hasDropShadow /> {reseller.systemName}</span>},
                                                    {name: "license_org", value: <span><Flag size='s' code={unit.country} hasDropShadow />{unit.orgName}</span>},
                                                    {name: "license_allowedNumberOfUnits", value: unit.allowedNumberOfUnits},
                                                    {name: "license_duplication_unit_count", value: numberOfUNits},
                                                    {name: "license_reference", value: unit.licenseRef},
                                                    {name: "license_name", value: unit.licenseName},
                                                ]}
                                            />
                                            <div className="bulk-actions">
                                                <DuplicateLicenseMailButton info={info} />
                                                <DuplicatedLicenseExtendAllowedButton 
                                                    info={info} 
                                                    onLicenseUpdated={mewMax => {
                                                        if(numberOfUNits <= mewMax){
                                                            setDuplicated(old => old.filter(x => x.licenseRef !== info.licenseRef));
                                                        }
                                                        else{
                                                            setDuplicated(old => old.map(i => i.licenseRef !== info.licenseRef 
                                                                ? i 
                                                                : {
                                                                    ...i, 
                                                                    units: i.units.map(u => ({...u, allowedNumberOfUnits: mewMax}))
                                                                } 
                                                            ))
                                                        }
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                        <ItemContainerTable
                                            items={info.units} 
                                            columns={[
                                                {id: "online", value: x => moment.unix(x.lastUpdateEpoch).fromNow()},
                                                {id: "unit_teamViewerId", value: x => x.teamViewerId},
                                                {id: "unit_ipAddress", value: x => x.ipAddress},
                                                {id: "unit_systemManufacturer", value: x => x.systemManufacturer},
                                                {id: "unit_systemProductName", value: x => x.systemProductName},
                                                {id: "unit_projectorModel", value: x => x.projectorModel},
                                                {id: "unit_projectorSerialNumber", value: x => x.projectorSerialNumber},
                                                {id: "unit_lampHours", value: x => x.lampHours},
                                                {id: "unit_cameraType", value: x => x.usbCameraType},
                                                {
                                                    id: 'actions', 
                                                    value: x => {
                                                        return(
                                                            <div className="actions">
                                                                {x.isLicenseBlocked &&
                                                                    <span><FontAwesomeIcon icon={'ban'} /> <Translate id='license_duplication_blocked' /></span>
                                                                }
                                                                {isAdmin && !x.isLicenseBlocked &&
                                                                    <Button icon={'ban'} name="license_duplication_block_btn" onClick={() => blockLicenseOnUnit(x)} />
                                                                }
                                                                <Button icon={x.isRemoteConfigUpdated ? 'check' : undefined} name={x.isRemoteConfigUpdated ? 'license_duplication_license_set' : 'storage_unit_set_license'} onClick={() => setShowSetLicense(x)} />
                                                            </div>
                                                        )
                                                    }  
                                                }
                                            ]} 
                                            pageSize={20}                            
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
            {showSetLicense &&
                <Dialog onClose={() => setShowSetLicense(undefined)}>
                    <RemoteConfigForm
                        computerId={showSetLicense.id}
                        platformType={showSetLicense.platformType}
                        onUpdate={remoteConfig => {
                            updateUnit({...showSetLicense, isRemoteConfigUpdated: remoteConfig.apiKey !== showSetLicense.apiKey, isLicenseBlocked: remoteConfig.isLicenseBlocked});
                            setShowSetLicense(undefined);
                        }}
                    />
                </Dialog>
            }
        </div>
    )
}

export default DuplicateLicenses;