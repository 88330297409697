import React, { useContext, useEffect, useMemo } from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Loading} from "./Loading";
import moment from "moment";
import { GlobalModalContext, SecureContext } from './_MyFloor/MyFloorApp';
import { eulaVersion } from '../App';
import { getExpireTime } from '../services/LocalStorageService';
import { AccountContext } from '../api/AccountContext';
import { FloorContext } from '../api/FloorContext';
import { useTranslation } from 'react-i18next';
import { isOrganizationType } from '../model/Organization';
import { isFetchError } from '../services/FetchHelper';
import { OrganizationContext } from '../api/OrganizationContext';
import { LicenseContext } from '../api/LicenseContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from './Helper/Translate';
import TextView from './TextView';

const SecureRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const {me, myOrg, setMe, setMyOrg, floorContent, setFloorContent} = useContext(SecureContext);
    const {addCurtain} = useContext(GlobalModalContext);
    const expiretime = getExpireTime();
    const expired = !!(!expiretime || expiretime.isBefore(moment.now()));

    const [getMe] = AccountContext.useMe();
    const [getFloorContent] = FloorContext.useFloorContent();
    const [getMyOrg] = OrganizationContext.useOrganization();
    const [getLicenseStatus] = LicenseContext.useStatus();

    const hasFirstname = useMemo(() => me?.firstname, [me]);
    const acceptedEula = useMemo(() => eulaVersion === me?.acceptedEulaVersion, [me]);

    useEffect(() => {
        myOrg && isOrganizationType(myOrg) && setMyOrg(myOrg);
    }, [myOrg, setMyOrg])

    useEffect(() => {
        if(me?.language){
            moment.locale(me?.language);
            i18n.changeLanguage(me?.language);
            const root = document.getElementById('root');
            if(root){
                root.className = me?.language === 'iw' ? "hebrew-font" : "";
            } 
        }
    }, [me?.language, i18n])

    //Check if we need to redirect to FirstVisit
    useEffect(() => {
        if(me && (!hasFirstname || !acceptedEula)){
            if(!location?.pathname.startsWith("/firstvisit")){
                navigate("/firstvisit")
            }
        }
    }, [hasFirstname, acceptedEula, location, me, navigate])

    //Check if we need to receive the users organization
    useEffect(() => {
        if(me && !myOrg){
            getMyOrg(me.organizationId).then(x => !isFetchError(x) && setMyOrg(x));
        }
    }, [me, myOrg, getMyOrg, setMyOrg]);

    //Check if we need to receive the users floor data
    useEffect(() => {
        !floorContent && me && getFloorContent().then(x => !isFetchError(x) && setFloorContent(x));
    }, [floorContent, me, getFloorContent, setFloorContent]);

     //Check if we need to log the user out, or fetch the logged in user.
    useEffect(() => { 
        if(expired){
            setMe(undefined);
            const returnQuery = location.pathname.length > 1 ? `?returnUrl=${location.pathname}`: '';
            const loginUrl = `/login${returnQuery}`;
            navigate(loginUrl);
        }
        else if(!me){
            getMe(); 
        }        
    }, [expired, location.pathname, navigate, setMe, getMe, me]);

    useEffect(() => {
        getLicenseStatus().then(x => {
            if(!isFetchError(x)){
                if(x.anySinglePaymentsExpired){
                    addCurtain(
                        <div className='expired-license-curtain'>
                            <FontAwesomeIcon icon='exclamation-triangle' /><Translate id='expire_warning'/>
                        </div>
                    );
                }
                if(x.expiringSinglePaymentLicense){
                    addCurtain(
                        <div className='expiring-license-curtain'>
                            {!x.expiringSinglePaymentLicense.text?.content &&
                                <>
                                    <FontAwesomeIcon icon='exclamation-triangle' />
                                    <Translate id='expiring_warning_placeholder' data={{daysLeft: x.expiringSinglePaymentLicense.daysLeft, licenseName: x.expiringSinglePaymentLicense.licenseName, licenseReference: x.expiringSinglePaymentLicense.licenseReference}}/>
                                </>
                            }
                            {x.expiringSinglePaymentLicense.text?.content && 
                                <TextView 
                                    element='div' 
                                    text={{
                                        ...x.expiringSinglePaymentLicense.text,
                                        content: x.expiringSinglePaymentLicense.text.content
                                            .replaceAll("{daysLeft}", x.expiringSinglePaymentLicense.daysLeft.toString())
                                            .replaceAll("{licenseName}", x.expiringSinglePaymentLicense.licenseName)
                                            .replaceAll("{licenseReference}", x.expiringSinglePaymentLicense.licenseReference.toString())
                                    }} 
                                />
                            }
                        </div>
                    );
                }
                else if(x.allTerminatedExpired){
                    addCurtain(
                        <div className='no-license-curtain'>
                            <FontAwesomeIcon icon='exclamation-triangle' /><Translate id='no_license_warning'/>
                        </div>
                    );
                }
                    
            }
        })
    }, [getLicenseStatus, addCurtain]); 

    //Return the Route if an account has been fetched and the token is not expired. (this is not a security check, that will happen server side and not in the JS.)
    return (
        <>
            {(!me || !myOrg) && <Loading visible={true} />}
            {me && myOrg && !expired && <Outlet />}
        </>
        
    );   
};


export default SecureRoute;