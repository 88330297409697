import { DbOrUploadImage } from '../../../services/ImageHelper';
import { Hint } from '../../../model/Game/Globetrotter/Hint';
import Board from '../../../images/games/globetrotter/Board_Blue.png';
import BoardCut from '../../../images/games/globetrotter/Board_Blue_cut_overlay.png';
import TextView from '../../TextView';
import { TextType } from '../../../model/Game/TextType';

interface Props {
    hint: Hint;
    hintTitle: TextType;
    onClick?: () => void;
}

const HintPreview = (props: Props) => {
    const { hint, hintTitle, onClick } = props;
    if (!hint) {
        return null;
    }

    return (
        <div onClick={onClick} className='hint-preview'>
            <img src={Board} className='board-bg' alt=''/>
            <DbOrUploadImage className='hint-image' image={hint.image} realSize />
            <div className='hint-text'>
                <TextView  element='div' text={hint.text} />
            </div>
            <TextView className='hint-title' element='div' text={hintTitle} />
            <img src={BoardCut} className='board-overlay' alt=''/>
        </div>
    )
}
 
export default HintPreview;