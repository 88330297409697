import React, { useCallback, useState } from "react";
import EditGameComponent from "../../EditGameComponent";
import { WordFinderContext } from "../../../api/Game/WordFinderContext";
import { hasInvalid, WordFinderConstants, WordFinderGame } from "../../../model/Game/WordFinder/WordFinderGame";
import { GameSettings } from "../../../api/BaseGameContext";
import WordFinderSettings from "./WordFinderSettings";
import { Word } from "../../../model/Game/WordFinder/Word";
import WordForm from "./WordForm";
import ItemContainer from "../../ModelPreview/ItemContainer";
import WordPreview from "./WordPreview";
import { Category } from "../../../model/Game/WordFinder/Category";
import CategoryForm from "./CategoryForm";
import CategoryPreview from "./CategoryPreview";
import WordFinderGamePreview from "./WordFinderGamePreview";
import Translate from "../../Helper/Translate";
import NewGameItemButton from "../NewGameItemButton";

const newWord: {word?: Word, categoryId?: string} = {}; 
const WordFinder = () => {
    const [game, setGame] = useState<WordFinderGame>();
    const [showWordForm, setShowWordForm] = useState<{word?: Word, categoryId?: string} | undefined>();
    const [showCategoryForm, setShowCategoryForm] = useState<{category: Category | null; preventDelete?: boolean} | undefined>(); 

    const newElementFunc = useCallback(() => setShowWordForm(newWord), []);

    const invalidWordsCount = game?.categories.flatMap(x => 
        x.words.filter(word => 
            hasInvalid(word.contentToFind, x.alphabet)
        )
    ).length || 0; 

    return (
        <EditGameComponent<WordFinderGame, GameSettings>
            contentClass="word-finder"
            game={game}
            setGame={setGame}
            gameContext={WordFinderContext}
            SettingsNode={WordFinderSettings}
            settingsMapper={g => ({skin: g.skin ?? "Default", document: g.document})}
            newGameElementFunction={newElementFunc}
            newGameElementText={'word_finder_create_word_button'}
            tempDescription={'word_finder_text'}
            tempTitle={'word_finder_create_title'}
            publishRequirements={[
                {done: !!game && game.categories.length > 0, name: 'word_finder_1_category_required', action: newElementFunc},
                {done: !!game && game.categories.filter(x => x.words.length > 2).length > 0, name: 'word_finder_3_words_in_1_category_required', action: newElementFunc},
                {done: invalidWordsCount === 0, name: 'word_finder_invalid_words', translationData: {count: invalidWordsCount}, action: newElementFunc}
            ]}
            publicRequirements={[]}
            PreviewNode={<WordFinderGamePreview game={game}/>}
        >   
            {game && <ItemContainer 
                items={game?.categories || []} 
                heading={<h2><Translate id='word_finder_grids_and_words'/></h2>}
                itemRender={x => 
                    <ItemContainer
                        heading={<CategoryPreview category={x} onClick={!game.deleted ? () => setShowCategoryForm({category: x}) : undefined}/>}
                        subheading={<div className="alphabet-preview"><Translate id='word_finder_alphabet_preview' data={{alphabet: x.alphabet}} /></div>}
                        items={x.words}
                        key={x.id}
                        itemRender={item => 
                            <WordPreview 
                                hasInvalidChars={hasInvalid(item.contentToFind, x.alphabet)} 
                                word={item} 
                                onClick={!game.deleted ? () => setShowWordForm({word: item, categoryId: x.id}) : undefined}
                                key={item.id}
                            />
                        }
                        vertical
                        after={
                            !game.deleted && <NewGameItemButton
                                onClick={() => setShowWordForm({categoryId: x.id})}
                                onHoverMsg={x.words.length >= x.grid.rowCount ? "word_finder_max_word_count_pr_category_reached" : "word_finder_create_word_button"}
                                disabled={x.words.length >= x.grid.rowCount}
                            />
                        }
                        className="grid-contents-preview"
                    />
                }
                after={
                    !game.deleted && <NewGameItemButton
                        onClick={() => setShowCategoryForm({category: null})}
                        onHoverMsg={(game?.categories.length || 0) >= WordFinderConstants.maxCategoryCount ? "word_finder_max_category_count_reached" : "word_finder_category_create"}
                        disabled={(game?.categories.length || 0) >= WordFinderConstants.maxCategoryCount}
                    />
                }
                className="word-finder-contents-preview"
            />}
            {showWordForm !== undefined && 
                <WordForm 
                    word={showWordForm.word} 
                    categoryId={showWordForm.categoryId} 
                    gameId={game?.id} 
                    onChange={setGame} 
                    onClose={() => setShowWordForm(undefined)} 
                    categories={game?.categories || []} 
                    onCategoryClick={x => setShowCategoryForm({category: x, preventDelete: x?.id === showWordForm?.categoryId})}
                />
            }
            {showCategoryForm !== undefined && 
                <CategoryForm 
                    category={showCategoryForm.category} 
                    onChange={setGame} 
                    onClose={() => setShowCategoryForm(undefined)} 
                    gameId={game?.id} 
                    preventDelete={showCategoryForm.preventDelete}
                />
            }
        </EditGameComponent>
    )
};

export default WordFinder;