import { useState } from "react";
import { FloorContext } from "../../../api/FloorContext";
import { WallContext } from "../../../api/WallContext";
import { License } from "../../../model/License";
import { PlatformType } from "../../../model/PlatformType";
import { Organization } from "../../../model/Organization";
import { isFetchError } from "../../../services/FetchHelper";


type ReturnType = [(License&{systemName: string})[], (platformType: PlatformType, reseller: Organization|undefined) => void, boolean];

const useTemplates = (): ReturnType => {
    const [getFloorTemplates, loadingFloorTemplates] = FloorContext.useOrgFloors();
    const [getWallTemplates, loadingWallTemplates] = WallContext.useOrgWalls();

    const [templates, setTemplates] = useState<(License&{systemName: string})[]>([]);

    const recalcTemplates = async (platformType: PlatformType, reseller: Organization|undefined) => {
        if(!reseller) return;

        let result: License[] = [];
        switch(platformType){
            case PlatformType.Floor:
                const f = await getFloorTemplates(reseller.id);
                if(!isFetchError(f)) result = f;
                break;
            case PlatformType.Wall:
                const w = await getWallTemplates(reseller.id);
                if(!isFetchError(w)) result = w;
                break;
            default: return;
        }

        //append the resellers licenses to the available templates, if the platformType has not changed.
        //If platformtype has changed, reset the available templates to the result values 
        setTemplates(t => {
            var mResult = result.map(x => ({...x, systemName: reseller.systemName}));
            if(t.length === 0) return mResult;
            if(t[0].platformType === platformType){
                if(result.length > 0) return [...t,...mResult];
                else return t;
            }
            return mResult;
        });
    }

    return [templates, recalcTemplates, loadingFloorTemplates||loadingWallTemplates];
}

export default useTemplates;