import React, { useContext, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSuperScriptText, getSubScriptText, getMathText } from "../../../services/TextHelper";
import FieldErrors from "../FieldErrors";
import UnsavedChangesIcon from "./UnsavedChangesIcon";
import { copyToClipboard } from "../../../services/CustomFunctions";
import { useTranslation } from "react-i18next";
import Translate from "../../Helper/Translate";
import { GlobalModalContext, SecureContext } from "../../_MyFloor/MyFloorApp";
import { FetchError } from "../../../services/FetchHelper";
import { ChangeEvent } from "../../../model/ChangeEvent";
import { Dict } from "../../../services/JsDict";

interface Props<T extends string> {
    name: string;
    type?: 'button'|'checkbox'|'color'|'date'|'datetime-local'|'email'|'file'|'hidden'|'image'|'month'|'number'|'password'|'radio'|'range'|'search'|'submit'|'tel'|'text'|'time'|'url'|'week';
    pattern?: string;
    value: T;
    onChange: (event: ChangeEvent<T>) => void;
    doneTyping?: () => void;
    errors?: Dict<string[]>|FetchError;
    errorField?: string;
    placeholder?: string;
    inputIcon?: IconProp;
    label?: string;
    disabled?: boolean;
    disabledHint?: string;
    tabIndex?: number;
    labelWidth100?: boolean;
    className?: string;
    clickToCopy?: boolean;
    autoCompleteOff?: boolean;
    noLabel?: boolean;
    noTranslate?: boolean;
    noTranslateErrors?: boolean;
    noUnsavedChanges?: boolean;
    readOnly?: boolean;
    autoFocus?: boolean;
}
const InputFormGroup = <T extends string>(props: Props<T>) => {
    const {name, autoFocus, type: _type, pattern, value, onChange, errors: _errors, errorField, placeholder, inputIcon: _inputIcon, label: _label, disabled, disabledHint, tabIndex, labelWidth100, className, clickToCopy, autoCompleteOff, noLabel, noTranslate, noUnsavedChanges, readOnly} = props;
    const type = _type ?? "text";
    const {isRtl} = useContext(SecureContext);
    const {popMsg} = useContext(GlobalModalContext);
    
    const {t} = useTranslation();
    const [original] = useState(value);
    const label = noLabel || <label className={labelWidth100 ? 'full-width' : ''} htmlFor={name}>{noTranslate ? _label || name : <Translate id={_label || name} />}</label>;
    const inputIcon = _inputIcon && <FontAwesomeIcon icon={_inputIcon} className='input-icon'/>;

    const showUnsaved = !noUnsavedChanges && original !== value;

    const input =
        <>
            <input
                name={name}
                placeholder={placeholder ? (t(placeholder) ?? '') : ''}
                dir="auto"
                className={`form-control${showUnsaved ? ' unsaved-changes' : ''}`}
                type={type}
                pattern={pattern}
                value={value}
                autoComplete={autoCompleteOff ? 'off' : undefined}
                readOnly={readOnly}
                onChange={(event) =>
                    onChange(
                        {target:
                            {
                                name: name,
                                // @ts-ignore
                                value: typeof(value) === 'number' ? parseFloat(event.target.value) : event.target.value
                            }
                        }
                    )
                }
                onKeyDown={(e) => {
                    if(e.ctrlKey && (e.key === "+" || e.key === "-" || e.key === "*")){
                        e.stopPropagation();
                        e.preventDefault();
                        const start = e.currentTarget.selectionStart;
                        const end = e.currentTarget.selectionEnd;
                        if(start !== null && end !== null) {
                            let selectedText = e.currentTarget.value.slice(start, end);
                            const t = e.currentTarget.value;
                            selectedText = e.key === "+"
                                ? getSuperScriptText(selectedText)
                                : e.key === "-"
                                    ? getSubScriptText(selectedText)
                                    : getMathText(selectedText);

                            const newText = `${t.slice(0, start)}${selectedText}${t.slice(end)}`;
                            onChange({
                                target: {
                                    name: name,
                                    // @ts-ignore
                                    value: typeof(value) === 'number' ? parseFloat(e.target.value) : newText
                                }
                            })
                        }
                    }
                }}
                disabled={disabled}
                tabIndex={tabIndex}
                autoFocus={tabIndex === 1 || autoFocus}
            />
            <UnsavedChangesIcon show={showUnsaved}/>
        </>;

    const errors = _errors && <FieldErrors errors={_errors} fieldname={errorField || name} />;

    const copyValue = () => {
        copyToClipboard(value);
        popMsg("pop_added", "pop_msg_clipboard_add");
    }

    return(
        <div 
            className={`form-group ${inputIcon ? 'icon-group' : ''} ${className || ''}${disabled && disabledHint ? ' hover-trigger' : ''}`} 
            onClick={e => clickToCopy ? copyValue() : undefined}
        >
            { label }
            {inputIcon && <div className={`icon-input-wrap${isRtl ? ' rtl' : ''}`}>{ inputIcon } { input }</div>}
            {!inputIcon && input }
            { errors }
            { disabled && disabledHint && <div className='hover-msg'><Translate id={disabledHint} /></div> }
        </div>
    );
};


export default InputFormGroup;


interface DelayedInputProps<T extends string> extends Props<T>{
    delayMs?: number;
}

export const DelayedInputFormGroup = <T extends string> (props: DelayedInputProps<T>) => {
    const {value: _value, onChange, delayMs} = props;
    const [value, _setValue] = useState<T>(_value);
    const [ ,setDelay] = useState<NodeJS.Timeout>();

    const setValue = (e: ChangeEvent<T>) => {
        _setValue(e.target.value);
        const delay = setTimeout(() => onChange(e), delayMs ?? 250)
        setDelay(d => {
            clearTimeout(d);
            return delay;
        });
    }
 
    return <InputFormGroup {...props} onChange={setValue} value={value} />

}