import { Price } from "./Price";

export interface FinancialData
{
    paymentType: PaymentType;
    reactivationDate: string;
    expireDate: string;
    price?: Price;
    paymentFrequency: number;
    prepaidMonths: number;
    renewalOrder?: RenewalOrder;

    /** @deprecated */
    renewDate: number;
    /** @deprecated */
    terminationDate: number;
    /** @deprecated */
    free: boolean;
}

export interface RenewalOrder{
    orderSource: OrderSource;
    resellerPO: string;
    lineText: string;
    startDate: string;
    endDate: string;
    createdEpoch: number;
    desiredInvoiceDate: string;
}

export type OrderSource = "Legacy"|"AutoRenew"|"Custom";

export const paymentTypes = ["Unknown","AutoRenew","SinglePayments","Terminated","Free"] as const;
export type PaymentType = typeof paymentTypes[number];