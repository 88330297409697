import React, { useEffect, useState } from 'react';
import { Currency, Price } from '../../../model/Price';
import Translate from '../../Helper/Translate';
import FieldErrors from '../FieldErrors';
import { FetchError } from '../../../services/FetchHelper';
import { ChangeEvent } from '../../../model/ChangeEvent';

interface Props{
    name: string;
    label?: string;
    labelTranslateData?: {[key: string] : string};
    value: Price;
    errors?: FetchError;
    errorKey?: string;
    disabled?: boolean;
    onChange: (event: ChangeEvent<Price>) => void;
}

const PriceFormGroup = (props: Props) => {
    const {name, label, labelTranslateData, value, errors, errorKey, disabled, onChange} = props;

    const [priceValue, setPriceValue] = useState<number|''>(value.value);
    useEffect(() => {
        if(value.value !== 0){
            setPriceValue(value.value);
        }
    }, [value.value])

    return(
        <div className='form-group currency'>
            <label className={'full-width'} htmlFor={name}><Translate id={label || name} data={labelTranslateData} /></label>
            <input
                type='number'
                value={priceValue}
                name={name}
                className={`form-control`}
                disabled={disabled}
                onChange={event => {
                    if(event.target.value === ''){
                        onChange({target:{name: name, value: {...value, value: 0}}});
                        setPriceValue('');
                    } 
                    else if(!isNaN(parseFloat(event.target.value))){
                        setPriceValue(parseFloat(event.target.value));
                        onChange({target: {
                            name: name,
                            value: {...value, value: parseFloat(event.target.value)}
                    }}) 
                }}}
            />
            <select
                name={name}
                className={`form-control`}
                onChange={event => onChange({target: {
                    name: name,
                    value: {...value, currency: event.target.value as Currency}
                }})}
                value={value.currency}
                disabled={disabled}
            >
                {
                    Object.values(Currency).map(c => <option key={c} value={c}>{c}</option>)
                }
            </select>
            {errors && <FieldErrors errors={errors} fieldname={errorKey || name} />}
        </div>
    )
}

export default PriceFormGroup;