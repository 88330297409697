import Dialog from "../../Dialog";
import { ImageFormButton, SubmitButton, TextTypeFormGroup } from "../../forms/FormGroup";
import Translate from "../../Helper/Translate";
import { Map } from "../../../model/Game/Globetrotter/Map";
import { Location } from "../../../model/Game/Globetrotter/Location";
import { Hint } from "../../../model/Game/Globetrotter/Hint";
import { FormEvent, useContext, useState } from "react";
import { GlobetrotterContext } from "../../../api/Game/GlobetrotterContext";
import { GlobetrotterEditingContext } from "../../EditingContext";
import { isFetchError } from "../../../services/FetchHelper";
import { TextAlignment, TextType, TextTypeStyle } from "../../../model/Game/TextType";
import HintPreview from "./HintPreview";
import { useConfirmDeleteAsync } from "../../forms/games/ConfirmationForm";
import { GeneralError } from "../../Error/GeneralError";
import { useStyle } from "../../../services/UseStyle";

interface Props {
    map: Map;
    onClose: () => void;
    onSubmit: (hint: Hint) => void;
    location: Location;
    hint?: Hint;
} 

const defaultEmptyText: TextType = {
    content: "",
    fontColorHex: "#000000",
    fontFamily: "Lato",
    fontSize: 50,
    textAlignment: TextAlignment.Center
}

const initialHint: Hint = { id: "", text: defaultEmptyText };

const EditHintForm = (props: Props) => {

    const { map, onClose, onSubmit, location, hint } = props;

    const { game, setGame } = useContext(GlobetrotterEditingContext);
    const [style, setStyle] = useStyle<TextTypeStyle>('globetrotter_hint_style', defaultEmptyText, ['content']);
    const [model, setModel] = useState<Hint>(hint ?? {...initialHint, text: {...initialHint.text, ...style}});
    const [createHint, loadingCreate, errorCreate] = GlobetrotterContext.useCreateHint();
    const [updateHint, loadingUpdate, errorUpdate] = GlobetrotterContext.useUpdateHint();
    const [deleteHint, loadingDelete, errorDelete] = GlobetrotterContext.useDeleteHint();

    const [confirmDelete, showConfirmDelete] = useConfirmDeleteAsync(
        map.id && model?.id && location?.id && game
            ? async () => deleteHint(game.id, map.id, location.id, model.id)
            : undefined,
        g => { setGame(g); onClose(); }
    );

    const submitClick = async (e: FormEvent) => {
        e.preventDefault();

        const cm = { ...model, reuseImage: false, reuseSound: false };
        if (map.id && location && location.id && game) {
            const result = hint ?
                await updateHint(game.id, map.id, location.id, hint.id, cm) :
                await createHint(game.id, map.id, location.id, cm);

            if (!isFetchError(result)) {
                setStyle(model.text);
                setGame(result);
            }
        }
        onSubmit(model);
    };

    return (
        <Dialog
            loading={loadingCreate || loadingUpdate || loadingDelete}
            onClose={onClose}
            icon={model?.id ? 'trash' : undefined}
            onIconClick={showConfirmDelete}
        >
            {confirmDelete}
            <h1><Translate id="globetrotter_edit_hint" /></h1>
            <form className="globetrotter-hint-form" onSubmit={submitClick}>
                <div className="flex">
                    <div>                       
                        <div>
                            <TextTypeFormGroup
                                text={model.text}
                                textChange={text => setModel(x => ({ ...x, text: text }))}

                                useTextArea
                            />
                        </div>
                        <label><Translate id="globetrotter_hint_image" /></label>
                        <ImageFormButton
                            name={'image'}
                            imageSubmit={image => setModel(x => ({ ...x, image: image }))}
                            previewWidth={374}
                            previewHeight={223}
                            value={model.image}
                            fixedAspect={true}
                            errors={errorCreate || errorUpdate}
                            errorKey={"Image"}
                        />
                    </div>
                    <div>
                        <HintPreview hint={model} hintTitle={location?.hintTitle} />
                    </div>
                </div>
                <GeneralError error={errorCreate || errorDelete || errorUpdate}/>
                <SubmitButton split />
            </form>
        </Dialog>
    );
}

export default EditHintForm;