import React, { FormEvent, useState } from 'react';
import { BaseGame } from '../../../model/Game/BaseGame';
import GoogleTranslateList, { deepLCodes, getLanguageName } from '../../../model/LocalizationVariables';
import { FetchError, isFetchError } from '../../../services/FetchHelper';
import Dialog from "../../Dialog";
import Translate from '../../Helper/Translate';
import FieldErrors from '../FieldErrors';
import { CheckBox, SelectLanguage, SubmitButton } from "../FormGroup";
import { useNavigate } from 'react-router-dom';
import { GeneralError } from '../../Error/GeneralError';
import { CreateCopyRequest } from '../../../api/BaseGameContext';
import { RadioGroup } from '../RadioGroup';
import { ChangeEvent } from '../../../model/ChangeEvent';
import { useTranslation } from 'react-i18next';

interface Props {
    game: BaseGame;
    invoke: (model: CreateCopyRequest) => Promise<BaseGame|FetchError>;
    onClose: () => void;
}

const CreateTranslationForm = (props: Props) => {
    const {game, invoke, onClose} = props;
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    const [model, setModel] = useState<CreateCopyRequest>({keepDocument: false, languageCode: game.languageCode, translator: "None"})

    const [error, setError] = useState<FetchError>();
    const [loading, setLoading] = useState(false);


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const result = await invoke(model);
        setLoading(false);
        if(isFetchError(result)){
            setError(result);
        }
        else{
            onClose();
            navigate(`/workshop/${result.gameType}/edit/${result.id}`);
        }
    }

    const setDstLanguage = (e: ChangeEvent<string>) => {
        setModel(o => ({
            ...o, 
            languageCode: e.target.value,
            translator: isTranslatable(e.target.value, o.translator) ? o.translator : "Google"
        }))
    }

    const srcTranslatable = isTranslatable(game.languageCode, model.translator);
    const dstTranslatable = isTranslatable(model.languageCode, model.translator);

    return(
        <Dialog
            onClose={onClose}
            loading={loading}
        >
            <form onSubmit={onSubmit} className='copy-form'>
                <h2><Translate id='copy_create' /></h2>
                <SelectLanguage
                    name={'originalLanguage'}
                    defaultValue={game.languageCode}
                    onChange={() => {}}   
                    errors={error}
                    disabled     
                    noLabel 
                    noUnsavedChanges       
                />
                <SelectLanguage
                    name={'LanguageCode'} 
                    defaultValue={model.languageCode}
                    onChange={setDstLanguage}   
                    errors={error}     
                    noLabel   
                    noUnsavedChanges     
                />
                <RadioGroup
                    name='translator'
                    label='copy_translator'
                    hideUnsaved
                    options={[
                        {name: "None", value: "None"}, 
                        {
                            name: "DeepL", 
                            value: "DeepL", 
                            disabled: !isTranslatable(model.languageCode, "DeepL") || !isTranslatable(game.languageCode, "DeepL"),
                            disabledHint: "langcode_translate_unavailable",
                            translateData: {
                                langCode: !isTranslatable(game.languageCode, "DeepL") 
                                    ? getLanguageName(i18n.language,game.languageCode) 
                                    : getLanguageName(i18n.language,model.languageCode)
                            }
                        }, 
                        {name: "Google", value: "Google"}
                    ]}
                    value={model.translator}
                    onChange={e => setModel(o => ({...o, translator: e as CreateCopyRequest["translator"]}))}
                    errorKey='translator'
                    errors={
                        !srcTranslatable || !dstTranslatable
                            ? { translator: [ !srcTranslatable ? "src_not_stranslateable" : "",  !dstTranslatable ? "dst_not_stranslateable" : "" ] }
                            : undefined
                    }
                />
                <CheckBox
                    name={'keepDocument'}
                    label={'document_keep'}
                    active={model.keepDocument}
                    onChange={(e) => setModel(o => ({...o, keepDocument: e.target.value}))}
                    errors={error}
                    disabled={!game.document}
                    hideUnsaved
                />
                {game.document && model.keepDocument && model.translator !== "None" && game.languageCode !== model.languageCode && <div className='form-group'><Translate id='document_no_translate_hint' /></div>}
                {error && <FieldErrors errors={error} fieldname={'preventCopy'} />}
                {error && <GeneralError error={error}/>}
                <SubmitButton
                    text='submit'
                    split
                    disabled={!model.languageCode}
                />
            </form>
        </Dialog>
    )
};

const isTranslatable = (language: string|undefined, translator: CreateCopyRequest["translator"]) => {
    switch(translator){
        case 'Google':
            return !!GoogleTranslateList.find(lang => lang.toLowerCase() === language?.toLowerCase());
        case 'DeepL':
            return !!deepLCodes.find(lang => lang.toLowerCase() === language?.toLowerCase());
        case 'None':
            return true;
    }
}


export default CreateTranslationForm;