import React from 'react';
import { errorStringsFromError } from '../../services/CustomFunctions';
import Translate from '../Helper/Translate';
import { Dict } from '../../services/JsDict';
import { FetchError, isFetchError } from '../../services/FetchHelper';


interface Props{
    errors: undefined | Dict<string[]> | FetchError; 
    fieldname: string
    noTranslate?: boolean;
    translateData?: {[key: string]: string};
}

const FieldErrors = (props: Props) => {
    const {errors, fieldname, noTranslate, translateData} = props;
    const errorStrings = errorStringsFromError(isFetchError(errors) ? errors.body : errors, fieldname);
    if(!errorStrings) return null;
    return(
        <div className='form-error-container'>
            {errorStrings.map(x => <div key={x} className='form-error'>{noTranslate ? x : <Translate id={x} data={translateData}/>}</div>)}
        </div>
    );     
};

export default FieldErrors;