import Dialog from "../../Dialog";
import { Button, SubmitButton, TextTypeFormGroup } from "../../forms/FormGroup";
import Translate from "../../Helper/Translate";
import { Location } from "../../../model/Game/Globetrotter/Location";
import InputFormGroup from "../../forms/FormGroups/InputFormGroup";
import ItemContainer from "../../ModelPreview/ItemContainer";
import { FormEvent, useContext, useState } from "react";
import { GlobetrotterContext } from "../../../api/Game/GlobetrotterContext";
import { GlobetrotterEditingContext } from "../../EditingContext";
import { isFetchError } from "../../../services/FetchHelper";
import { Hint } from "../../../model/Game/Globetrotter/Hint";
import EditHintForm from "./EditHintForm";
import HintItem from "./HintItem";
import { useConfirmDeleteAsync } from "../../forms/games/ConfirmationForm";
import { TextAlignment, TextType } from "../../../model/Game/TextType";
import { GeneralError } from "../../Error/GeneralError";

interface Props {
    mapId: string;
    onClose: () => void;
    onSubmit: (location: Location) => void;
    location?: Location;
}
 
const initialText: TextType = { content: "", fontColorHex: "#FFFFFF", fontFamily: "Secular One", fontSize: 34, textAlignment: TextAlignment.Center};

const initialLocation: Location = { name: "", hints: [], hintTitle: initialText, x: 0, y: 0, radius: 50, id: "" };

const EditLocationForm = (props: Props) => {
    const { mapId, onClose, onSubmit: onSomething, location } = props;
    const inLocation = location ?? initialLocation;
    const [model, setModel] = useState<Location>({ ...inLocation, hintTitle: inLocation.hintTitle === null ? initialText : inLocation.hintTitle });

    const [createLocation, loadingCreate, errorCreate] = GlobetrotterContext.useCreateLocation();
    const [updateLocation, loadingUpdate, errorUpdate] = GlobetrotterContext.useUpdateLocation();
    const [deleteLocation, loadingDelete, errorDelete] = GlobetrotterContext.useDeleteLocation();

    const [showHintEditForm, setShowHintEditForm] = useState<boolean>();
    const [editHint, setEditHint] = useState<Hint>();

    const { game, setGame } = useContext(GlobetrotterEditingContext);

    const [confirmDelete, showConfirmDelete] = useConfirmDeleteAsync(
        mapId && model?.id && game
            ? async () => deleteLocation(game.id, mapId, model.id)
            : undefined,
        g => { setGame(g); onClose(); }
    );

    const map = game?.maps.find(x => x.id === mapId);

    const submitClick = async (e: FormEvent) => {
        e.preventDefault();

        const cm = { ...model };
        if (mapId && game) {
            const result = location ?
                await updateLocation(game.id, mapId, location.id, cm) :
                await createLocation(game.id, mapId, cm);

            if (!isFetchError(result)) {
                setGame(result);
                // onSomething(result);
            }
        }
        onSomething(model);
    }

    const editExistingHintClick = (hint: Hint) => {
        setEditHint(hint);
        setShowHintEditForm(true);
    }

    const newHintClick = () => {
        setEditHint(undefined);
        setShowHintEditForm(true);
    }

    const closeHintForm = () => {
        setEditHint(undefined);
        setShowHintEditForm(false);
    }

    return (
        <Dialog
            loading={loadingCreate || loadingUpdate || loadingDelete}
            onClose={onClose}
            icon={model?.id ? 'trash' : undefined}
            onIconClick={showConfirmDelete}
        >
            {confirmDelete}
            <h1><Translate id="globetrotter_edit_location" /></h1>
            <form  onSubmit={submitClick}>
                <div className="flex">
                    <div>
                        <InputFormGroup
                            name='locationName'
                            labelWidth100
                            label="globetrotter_location_name"
                            type='text'
                            value={model?.name ?? ""}
                            placeholder='globetrotter_location_name'
                            onChange={(e) => setModel(x => ({ ...x, name: e.target.value }))}
                            tabIndex={1}
                        />
                        <label><Translate id="globetrotter_hints_title" /></label>
                        <TextTypeFormGroup
                            noLabel
                            noTextAlign
                            text={model.hintTitle}
                            textChange={text => setModel(x => ({ ...x, hintTitle: text }))}
                        />
                    </div>
                    <div>
                        <ItemContainer
                            vertical
                            className="globetrotter-hint-list-form"
                            items={location?.hints ?? []}
                            itemRender={hint => <HintItem hint={hint} hintTitle={model.hintTitle} onClick={editExistingHintClick} />}
                            heading={<h2><Translate id='globetrotter_hints' /></h2>}
                            subheading={<Button className='margin-top' name='globetrotter_add_hint' icon={"plus"} onClick={newHintClick} />}
                        />
                    </div>
                </div>

                <GeneralError error={errorCreate || errorUpdate || errorDelete}/>
                <SubmitButton split />
            </form>
            {showHintEditForm && map && model && game &&
                <EditHintForm
                    map={map}
                    onClose={closeHintForm}
                    onSubmit={closeHintForm}
                    hint={editHint}
                    location={model}
                />
            }
        </Dialog>
    )
}

export default EditLocationForm