import React, {useContext, useEffect, useRef, useState} from 'react';
import Game from "../ModelPreview/Game";
import ComponentSlider from "./ComponentSlider";
import {Loading} from "../Loading";
import Author from "../ModelPreview/Author";
import {DictGetValues} from "../../services/JsDict";
import {AccountType, getFullName} from "../../model/AccountType";
import {OrganizationName} from "./OrganizationName";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FeaturedGameView from "./FeaturedGameView";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import { getLanguageName } from '../../model/LocalizationVariables';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { SecureContext } from '../_MyFloor/MyFloorApp';
import { SearchResult } from '../../model/Response/SearchResult';
import { BaseGame } from '../../model/Game/BaseGame';
import { GameContext } from '../../api/Game/GameContext';
import { isFetchError } from '../../services/FetchHelper';
import { BannerContext } from '../../api/BannerContext';
import { FeaturedGame } from '../../model/FeaturedGame';
import { PlaylistContext } from '../../api/PlaylistContext';
import { PlayListType } from '../../model/PlayListType';
import Translate from '../Helper/Translate';
import { AccountContext } from '../../api/AccountContext';
import { SortByCustom } from '../../services/SortFunctions';
import { DiscoverContext } from '../../api/DiscoverContext';
import { Link } from 'react-router-dom';

interface State{
    ColleaguesIndex?: number;
    PublicIndex?: number;
    FollowingsIndex?: number;
}

const minVerifiedCount = 20; 

const Discover = () => {
    const {me, myOrg} = useContext(SecureContext);

    const [getOrgGames, loadingOrgGames] = GameContext.useOrganizationGameSearch();
    const [getBanners] = BannerContext.useBanners();
    const [getNewestPlaylists, loadingNewestPlaylist] = PlaylistContext.useNewestInPrimaryLanguage();
    const [getMyLanguageGames, loadingMyLanguageGames] = GameContext.useMyLanguageGames();
    const [getMyLanguageVerifiedGames, loadingMyLanguageVerifiedGames] = GameContext.useMyLanguageVerifiedGames();
    const [getMyLanguageGamesEdit, loadingMyLanguageGamesEdit] = GameContext.useMyLanguageGamesEdit();
    const [getStaticGames, loadingStaticGames] = GameContext.useStaticGame();
    const [getFollowlistGames, loadingFollowlistGames] = GameContext.useGamesFromFollowlist();
    const [getPublicGames, loadingPublicGames] = GameContext.usePublicGames();
    const [getAccountFollowings, loadingAccountFollowings] = AccountContext.useFollowings();
    const [getRandomAccoounts, loadingRandomAccounts] = AccountContext.useRandom();
    const [getOrgAccounts, loadingOrgAccounts] = AccountContext.useByOrganization();
    const [getTrendingGames, loadingTrendingGames] = DiscoverContext.useTrendingGames();
    const [getGamesMatchingSubjects, loadingGamesMatchingSubjects] = GameContext.useGamesMatchingSubjects(); 

    const [state, setState] = useState<State>({});
    const [orgGamesResult, setOrgGamesResult] = useState<SearchResult<BaseGame>>();
    const [banners, setBanners] = useState<FeaturedGame[]>([]);
    const [newestPlaylists, setNewestPlaylists] = useState<PlayListType[]>([]);
    const [myLanguageGames, setMyLanguageGames] = useState<BaseGame[]>([]);
    const [myLanguageVerifiedGames, setMyLanguageVerifiedGames] = useState<BaseGame[]>([]);
    const [myLanguageGamesEdit, setMyLanguageGamesEdit] = useState<BaseGame[]>([]);
    const [staticGames, setStaticGames] = useState<BaseGame[]>([]);
    const [followlistGames, setFollowlistGames] = useState<BaseGame[]>([]);
    const [publicGames, setPublicGames] = useState<BaseGame[]>([]);
    const [followings, setFollowings] = useState<AccountType[]>([]);
    const [randomAccounts, setRandomAccounts] = useState<AccountType[]>([]);
    const [orgAccounts, setOrgAccounts] = useState<AccountType[]>([]);
    const [trendingGames, setTrendingGames] = useState<BaseGame[]>([]);
    const [gamesMatchingSubjects, setGamesMatchingSubjects] = useState<BaseGame[]>([]); 

    useEffect(() => {
        if(me){
            getFollowlistGames().then(x => !isFetchError(x) && setFollowlistGames(x));
            getAccountFollowings(me.id).then(x => !isFetchError(x) && setFollowings(x));
            getOrgAccounts(me.organizationId).then(x => !isFetchError(x) && setOrgAccounts(DictGetValues(x)));
            getTrendingGames().then(x => !isFetchError(x) && setTrendingGames(x));
            getGamesMatchingSubjects().then(x => !isFetchError(x) && setGamesMatchingSubjects(x))
        }
    },[me, getAccountFollowings, getFollowlistGames, getOrgAccounts, getTrendingGames, getGamesMatchingSubjects]);

    useEffect(() => {
        me?.organizationId && getOrgGames(me.organizationId, {size: 42, sort: 'createdDateTime', ascending: false}).then(x => !isFetchError(x) && setOrgGamesResult(x));
    }, [me?.organizationId, getOrgGames]);

    useEffect(() => {
        getBanners().then(x => !isFetchError(x) && setBanners(x));
        getMyLanguageGames().then(x => !isFetchError(x) && setMyLanguageGames(x));
        getMyLanguageVerifiedGames().then(x => !isFetchError(x) && setMyLanguageVerifiedGames(x));
        getMyLanguageGamesEdit().then(x => !isFetchError(x) && setMyLanguageGamesEdit(x));
        getPublicGames(42).then(x => !isFetchError(x) && setPublicGames(x));
        getStaticGames().then(x => !isFetchError(x) && setStaticGames(x));
        getRandomAccoounts(100).then(x => !isFetchError(x) && setRandomAccounts(x));
        getNewestPlaylists().then(x => !isFetchError(x) && setNewestPlaylists(x));
    }, [getBanners, getNewestPlaylists, getMyLanguageGames, getMyLanguageVerifiedGames, getMyLanguageGamesEdit, getPublicGames, getStaticGames, getRandomAccoounts]);

    const isFollowing = me && me.followings?.length > 0;

    return(
        <div className='discover'>
            {banners.length > 0 &&
                <CarouselProvider
                    naturalSlideHeight={400}
                    naturalSlideWidth={1920}
                    isIntrinsicHeight
                    totalSlides={banners.length}
                    interval={5000}
                    dragEnabled={true}
                    isPlaying
                    infinite
                >
                    <Slider>
                        {banners.sort((a,b) => SortByCustom(a,b,"sort", true)).map((x,i) =>
                            <Slide key={x.id} index={i}><FeaturedGameView fg={x}/></Slide>
                        )}
                    </Slider>
                    {banners.length > 1 &&
                        <>
                            <ButtonNext className='carousel-btn next'>{'\u232A'}</ButtonNext>
                            <ButtonBack className='carousel-btn back'>{'\u2329'}</ButtonBack>
                        </>
                    }
                </CarouselProvider>
            }
            <div className='rows'>
                {(loadingGamesMatchingSubjects || gamesMatchingSubjects.length > 0) && 
                    <GameRow 
                        games={gamesMatchingSubjects} 
                        loading={loadingGamesMatchingSubjects} 
                        title='discover_games_matching_subject' 
                    />
                }
                {(loadingTrendingGames || !!trendingGames.length) &&
                    <GameRow 
                        games={trendingGames} 
                        loading={loadingTrendingGames} 
                        title='discover_trending_games' 
                        translationData={{country: myOrg?.country || 'Unknown'}} 
                    />
                }
                {myLanguageVerifiedGames.length > minVerifiedCount && 
                    <GameRow 
                        games={myLanguageVerifiedGames} 
                        loading={loadingMyLanguageVerifiedGames} 
                        title='verified_language_games' 
                        translationData={{ language: getLanguageName(me?.language, myOrg?.primaryLanguageCode || 'en') }} 
                    />
                }
                {myOrg?.primaryLanguageCode && 
                    <>
                        <GameRow 
                            games={myLanguageGames} 
                            loading={loadingMyLanguageGames} 
                            title='language_games' 
                            translationData={{ language: getLanguageName(me?.language, myOrg.primaryLanguageCode) }} 
                        />
                        <GameRow
                            games={myLanguageGamesEdit} 
                            loading={loadingMyLanguageGamesEdit} 
                            title='language_games_edit' 
                            translationData={{ language: getLanguageName(me?.language, myOrg.primaryLanguageCode) }} 
                        />
                    </>
                }
                <GameRow games={orgGamesResult?.items ?? []} title='organization_games' loading={loadingOrgGames} />
                <div className='row'>
                    <Loading visible={loadingNewestPlaylist}/>
                    <h2><Translate id='discover_newest_playlists' data={{language: getLanguageName(me?.language, myOrg?.primaryLanguageCode ?? "")}} /></h2>
                    <ComponentSlider width={220} height={240} spacing={10} className='game-row'>
                        {newestPlaylists.map(pl => <PlayListPreview key={pl.id} playlist={pl}/>)}
                    </ComponentSlider >
                </div>
                {isFollowing && <GameRow games={followlistGames} loading={loadingFollowlistGames} title='account_followings_games' />}
                <GameRow games={publicGames} loading={loadingPublicGames} title='public_games' />
                <GameRow games={staticGames} loading={loadingStaticGames} title='static_games' />
                {isFollowing && 
                    <AuthorRow 
                        authors={followings} 
                        loading={loadingAccountFollowings} 
                        title='pp_following' 
                        active={state.FollowingsIndex} 
                        setActive={i => setState({FollowingsIndex: i})} 
                    />
                }
                <AuthorRow 
                    authors={orgAccounts} 
                    loading={loadingOrgAccounts} 
                    title='accounts_in_my_organization' 
                    active={state.ColleaguesIndex} 
                    setActive={i => setState({ColleaguesIndex: i})} 
                />
                <AuthorRow
                    authors={randomAccounts}
                    loading={loadingRandomAccounts}
                    title='random_public_authors'
                    active={state.PublicIndex}
                    setActive={i => setState({PublicIndex: i})}
                />
            </div>
        </div>
    );

}

export default Discover;


interface GameRowProps{
    games: BaseGame[];
    title: string;
    translationData?: {[key: string]: string};
    loading: boolean;
}

const GameRow = (props: GameRowProps) => {
    const {games, title, translationData, loading} = props;
    return(
        <div className='row'>
            <Loading visible={loading}/>
            <h2><Translate id={title} data={translationData} /></h2>
            <ComponentSlider width={220} height={290} spacing={10} className='game-row'>
                {games.map(game => <Game menuHideEdit key={game.id} game={game}/>)}
            </ComponentSlider >
        </div>
    )
};

interface AuthorRowProps{
    authors: AccountType[];
    loading: boolean;
    title: string;
    active?: number;
    setActive: (i: number|undefined) => void;
}

const AuthorRow = (props: AuthorRowProps) => {
    const {authors, loading, title, active, setActive} = props;
    const [getPopularGames, loadingPopularGames] = GameContext.useAccountPopularGames();
    const ref = useRef<HTMLDivElement>(null);
    const [games, setGames] = useState<BaseGame[]>([]);

    const authorSelect = (i: number, accountId: string) => {
        setGames([]);
        getPopularGames(accountId).then(x => !isFetchError(x) && setGames(x));
        setActive(i);
    }

    const hoverAuthor = authors && active !== undefined && authors[active];

    if(ref.current){
        ref.current.scrollIntoView({behavior: "smooth"});
    }
    
    return(
        <div className='row row-author'>
            <Loading visible={loading}/>
            <h2 dir='auto'><Translate id={title} /></h2>
            <ComponentSlider width={100} height={116} spacing={10}>
                {authors.map((author, i) => 
                    author.firstname
                    ? <Author key={author.id} account={author} onClick={() => authorSelect(i, author.id)}/>
                    : null
                )}
            </ComponentSlider>
            {hoverAuthor &&
                <div className='author-card' ref={ref}>
                    <FontAwesomeIcon icon='times' className='close-x' onClick={() => setActive(undefined)} />
                    <div className='left'>
                        <div className='account-title'>
                            <Link to={`/library/profile/${hoverAuthor.id}`}>
                                <div className='author-name'>
                                    {getFullName(hoverAuthor)}
                                    <span className='to-public'><Translate  id={'account_public'}/> &gt;</span>
                                </div>
                            </Link>
                            <OrganizationName orgId={hoverAuthor.organizationId} />
                        </div>
                        {hoverAuthor.preferences && (hoverAuthor.preferences.subjects.length + hoverAuthor.preferences.subjectAreas.length) > 0 && 
                            <div className='taglist' dir='auto'>
                                {[...hoverAuthor.preferences.subjects, ...hoverAuthor.preferences.subjectAreas].map(x => 
                                    <div key={x} className='tag'>
                                        <Translate id={x}/>
                                    </div>
                                )}
                            </div>
                        }
                        <div className='author-description'>{hoverAuthor.description}</div>
                        <div className='popular-games'>
                            <Loading visible={loadingPopularGames} />
                            {games.map(g => <Game key={g.id} game={g} />)}
                        </div>
                    </div>
                    <Author className='right' account={hoverAuthor} fullImage={true} hideName />
                </div>
            }
        </div>
    )
};